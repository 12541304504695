import {headers, headerMap} from '../../CreateCSVReports/utilities/headerOrder'
import {iterateIntake} from '.'
const filteredHeaders = headers.filter(header => header !== 'Client ID');

export default function formatProjects(projects) {
  if (!projects || !projects.length) return null
  const [header, questionHeaders] = makeHeader(projects) // header is full header, questionHeaders only questions
  //console.log('header',header) 
  //console.log('questionHeaders',questionHeaders)
  const rows = projects.map((project) => formatProject(questionHeaders, project))
  //console.log('rows',rows)
  return [header, ...rows]
}

function formatProject(questionHeaders,project) {
  const hm = headerMap(project)
  const basicInformation = filteredHeaders.map((h) => hm[h])
  //console.log('questionHeaders',questionHeaders)
  const scoringInformation = mapHeadersToValues(questionHeaders, project)
  //console.log('basicInformation',basicInformation)
  //console.log('scoringInformation>>>',scoringInformation)
  return [...basicInformation, project.intake.id, project.rubric.id, ...scoringInformation]
}

function mapHeadersToValues(headers, project) {
  const mappedValues = [];
  const scoredData = project?.intakeResult?.rawData?.scored || {};
  
  headers.forEach(header => {
      const [sectionId, questionId, type] = header.split('.');
      
      const matchingEntry = Object.values(scoredData).find(entry => 
          entry.sectionId === sectionId && entry.id === questionId
      );
      
      if (matchingEntry) {
          if (type === 'question') {
              mappedValues.push(matchingEntry.question || "");
          } else if (type === 'score') {
              mappedValues.push(matchingEntry.score || "");
          } else if (type === 'answer') {
              mappedValues.push(matchingEntry?.value?.values ? Object.keys(matchingEntry.value.values) : "");
          } else {
              mappedValues.push("");
          }
      } else {
          mappedValues.push("");
      }
  });
  
  return mappedValues;
}

function generateSectionFields(project) {
  const result = [];
  // Loop through the order of section IDs
  project?.intake?.order?.forEach(sectionId => {
      const section = project.intake.sections[sectionId];
      if (section && section.fields && section.order) {
          section.order.forEach(fieldId => {
              const prefix = `${sectionId}.${fieldId}`;
              //result.push(`${prefix}.question`, `${prefix}.answer`, `${prefix}.score`);
              result.push(`${prefix}.answer`, `${prefix}.score`);
          });
      }
  });
  return result;
}

function makeHeader(projects) {
  const scoringHeader = generateSectionFields(projects[0])
  //console.log('scoring header',scoringHeader)
  //console.log('project 0',projects[0])
  const scoringHeadersLabels = []
  scoringHeader.forEach((eachHeader) => {
      const parts =  eachHeader.split(".")
      if (parts[2] === 'score') {
        scoringHeadersLabels.push('Pass/Fail')
      } else if (parts[2] === 'answer') { 
          let totalString = []
          const label = projects?.[0]?.intake?.sections?.[parts[0]]?.fields?.[parts[1]]?.label ?? '';
          if (label.length > 0) {
            totalString.push(label)
          }

          let question = projects?.[0]?.intake?.sections?.[parts[0]]?.fields?.[parts[1]]?.question ?? '';
          if (question.length > 0) {
            question = question.replace(/<[^>]+>/g, '')
            totalString.push(question)
          }

          const tipText = projects?.[0]?.intake?.sections?.[parts[0]]?.fields?.[parts[1]]?.tipText ?? '';
          if (tipText.length > 0){
            totalString.push(tipText)
          }

          scoringHeadersLabels.push(totalString.join(` | \n`))
          //scoringHeadersLabels.push(`${label} | \n ${question} | \n ${tipText}`)
          //scoringHeadersLabels.push(`"${label}\n${question}\n${tipText}"`);
          //scoringHeadersLabels.push(`"${label.replace(/"/g, '""')} \n ${question.replace(/"/g, '""')} \n ${tipText.replace(/"/g, '""')}"`);
      } else {
        //const readable = projects[0].intake.sections[parts[0]].fields[parts[1]].label ?? ''
        // const readable = projects?.[0]?.intake?.sections?.[parts[0]]?.fields?.[parts[1]]?.label ?? '';
        // readable.length > 0 ? readable : eachHeader
        // scoringHeadersLabels.push(readable.length > 0 ? `${readable}:${parts[2]}` : eachHeader)
        scoringHeadersLabels.push(eachHeader)
      }
  })
  //console.log('scoringHeadersLabels',scoringHeadersLabels)
  //return [...filteredHeaders, 'Form ID', 'Rubric ID', ...scoringHeadersLabels]
  return [[...filteredHeaders, 'Form ID', 'Rubric ID', ...scoringHeadersLabels], [...scoringHeader]]
}

function getScoringInformation(project) {
  return iterateIntake(project, (s, f) => [f.question, f.answer, f.score]).flat()
}
