import React, {useEffect, useRef, useState} from 'react'
import {saveAs} from 'file-saver'
import {Icon, Button} from 'antd'
import {firebaseConfig, FUNCTIONS_URL} from '../../config'
import {auth} from '../../Auth'
import {correctedURL, firebase} from './../../utilities'
import DB from '../../DAL/DB'
import moment from 'moment'
import axios from 'axios'

const projects = new DB.Projects()

export default function SaveReport({id, status, report, site, intake, regenerate_report }) {
  const className = `project-report ${report && report.fullPath ? 'color-primary' : 'color-quaternary'}`
  const [disabled, setDisabled] = useState(false)
  const [disableRegenerateLink, setDisableRegenerateLink] = useState(false)
  const disableRegenerateLinkRef = useRef(false)

  const {fullPath} = report
  let url
  if (fullPath) {
    url = correctedURL(fullPath)
  }

  // async function generateReport() {
  //   setDisabled(true)
  //   axios.post(`${FUNCTIONS_URL}/utilities/new_report` , { id, options: {} })
  //   .then((res) => {
  //     console.log('res newreport',res)
  //   }).catch(Error => {
  //       console.log('Error new_report',Error)
  //   })

  //   // const r = await (await fetch(`${FUNCTIONS_URL}/utilities/new_report/${id}`)).text()
  //   // console.log('r',r)
  //   // if (r !== 'success') {
  //   //   //console.log(r)
  //   //   return
  //   // }

  //   setTimeout(() => setDisabled(false), 10000)
  // }

  async function generateReport() {
    setDisabled(true)
    regenerateReport(false, 'missing')
    setTimeout(() => setDisabled(false), 10000)
  }

  async function regenerateReport(displayAlert = true, type='regenerate') {
    disableRegenerateLinkRef.current = true
    setDisableRegenerateLink(true)
    await projects.updatebatch(
      id,
      {noMerge: false},
      {
        regenerate_report: {
          by: auth.sunkaizenUser.id,
          at: firebase.firestore.FieldValue.serverTimestamp(),
          status: 'processing',
          type
        },
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: auth?.sunkaizenUser?.id, 
        updatedByName: auth?.sunkaizenUser?.name
      }
    )
    // setTimeout(() => { 
    //   setDisableRegenerateLink(false)
    //   disableRegenerateLinkRef.current = false
    // }, 10000)
    //alert('The report is being generated, the completion email will not be sent!')
    if (displayAlert) {
      alert('Please confirm you wish to regenerate the report.  Note that the completion notification will not be re-sent.')
    }
  }

  // function downloadFile(uri,name) {
  //   var link = document.createElement('a')
  //   link.href = uri
  //   link.setAttribute('download', name)
  //   document.body.appendChild(link)
  //   link.click()
  //   link.remove()
  // }


  const downloadFile = (uri, name) => {
    // Use axios to fetch the file
    axios({
        url: uri,
        method: 'GET',
        responseType: 'blob', // Important: Set response type to blob
    })
    .then(response => {
        // Create a new blob URL for the downloaded file
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name); // Set the custom name
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Free up memory
    })
    .catch(error => {
        console.error('Download error:', error);
    });
  }
 

  const coverntFiletoBlobAndDownload = async (file, name) => {
     downloadFile(file,name);
  }

  const disableBtn = async () => {
    try {
      if(regenerate_report && regenerate_report.status && regenerate_report.status === 'processing') {
        const createdDate = regenerate_report.at.toDate(); // Convert Firestore Timestamp to JS Date
        const now = new Date(); // Get current time
        const differenceInSeconds = (now - createdDate) / 1000; // Convert ms to seconds
        if (differenceInSeconds > 20) {
          setDisableRegenerateLink(false)
        } else {
          setDisableRegenerateLink(true)
          console.log("Document is NOT older than 20 seconds.");
        }
       } else if(regenerate_report && regenerate_report.status === 'completed') {
          setDisableRegenerateLink(false)
          disableRegenerateLinkRef.current = false
       }
    } catch (error) {
      setDisableRegenerateLink(false)
      disableRegenerateLinkRef.current = false
    }
  }

  // useEffect(()=> {
  //   disableBtn()
  // },[regenerate_report])

   useEffect(()=> {
    console.log('regenerate_report>>',regenerate_report, disableRegenerateLinkRef.current)
    if (regenerate_report && regenerate_report.status && regenerate_report.status === 'completed' && disableRegenerateLinkRef.current === true) {
      disableRegenerateLinkRef.current = false
      alert('Report is generated successfully.')
    }
  },[regenerate_report])



  return (
    <div style={{marginTop: '2rem'}}>
      {status === 'Completed' &&
        ['admin', 'super-admin', 'client'].includes(auth.sunkaizenUser.type) &&
        !Object.keys(report || {}).length && (
          <Button disabled={disabled} onClick={generateReport}>
            Generate a Missing Report
          </Button> 
        )}
      <>
        <a
          className={className}
          style={{pointerEvents: report && report.fullPath ? `auto` : `none`}}
          onClick={() => {
            //String(site.siteAddress).replace(/ /gi, '_')
            const fileName = `${site.siteAddress}_${moment().format('MM-DD-YYYY')}_${site.siteOwnerName}_${id}_report.pdf`
            //console.log('fileName',fileName,'url',url)
            coverntFiletoBlobAndDownload(url, fileName)
          }}
        >
          <Icon className="padding-right-1" type="project" />
          Project Report
        </a>
        {status === 'Completed' && (
          <a
            onClick={() => {
              regenerateReport()
            }}
          >
            Regenerate
          </a>
        )}
      </>
    </div>
  )
}
