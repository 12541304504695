import { auth } from '../../Auth';
import { validateResource, siteValidators, projectValidators, errorNameConverter } from '../../validators';
import { projectModify, projectsOne } from '.';

export default async function finalizeSingleProject(id, project, fields, logger, allowCreateProject = false) {
  try {
    const validationTargets = { ...siteValidators, ...projectValidators };
    
    if (allowCreateProject) {
      if (auth.sunkaizenUser.organization.id !== fields.inspector.organization.id) {
        delete validationTargets.client;
        delete validationTargets.projectType;
        delete fields.projectType;
        delete fields.client;
      }
    } else if (auth.sunkaizenUser.organization.id !== fields.organization.id) {
      delete validationTargets.client;
      delete validationTargets.projectType;
      delete fields.projectType;
      delete fields.client;
    }

    const errors = validateResource(fields)(validationTargets);
    const fatalErrors = [];

    for (let error of errors) {
      const [[key, value]] = Object.entries(error);
      if (value.length > 0) {
        fatalErrors.push([key, value]);
      }
    }

    if (fatalErrors.length > 0) {
      // Create a unique list of friendly field names that have errors.
      const errorFieldNames = fatalErrors.map(([key]) => errorNameConverter[key] || key);
      const uniqueErrorFieldNames = [...new Set(errorFieldNames)];
      // Build an aggregated message with new lines.
      const aggregatedMessage = `The following fields are missing or invalid:\n- ${uniqueErrorFieldNames.join(
        '\n- '
      )}\nPlease provide the required input.`;
      logger.error(aggregatedMessage);
      throw new Error(`Validation error: project not created\n${aggregatedMessage}`);
    }

    if (allowCreateProject) {
      await projectsOne(fields, auth.currentUser.uid, allowCreateProject);
      return 'success';
    } else if (id) {
      await projectModify(project, fields, auth.currentUser.uid);
      logger.categoryId = project.id;
      logger.log(`Project with address ${project.site.siteAddress} was successfully updated`);
      return 'success';
    } else {
      await projectsOne(fields, auth.currentUser.uid);
      return 'success';
    }
  } catch (e) {
    logger.category = null;
    logger.categoryId = null;
    if (e.message === 'ADDRESS_FAILED_GEOCODING') {
      console.log(e);
      logger.error(
        'Could not geocode the address you provided; please provide a valid address or choose a point on the map'
      );
    } else {
      logger.error(`Project creation failed; contact your system administrator if this issue persists`);
    }
    throw e;
  }
}
