import {auth} from '../../Auth'

export function filterClients(clients) {
  switch (auth.sunkaizenUser.type) {
    case 'client':
      return [auth.sunkaizenUser]
    case 'admin':
    case 'super-admin':
      return clients
    default:
      const cc = {}
      for (let i in clients) {
        cc[i] = clients[i]
      }
      return Object.keys(auth.sunkaizenUser.clients || {}).map((cId) => cc[cId])
  }
}

export function makeFilterProps(args) {
  const { filters, clearFilters, setFilters, clients, statuses: intakeTypes, type } = args;

  const clientTypes = clients
    .map((client = {}) => {
      if (client.name && client.id) return { id: client.id, name: client.name };
    })
    .filter((i) => i);

  return { auth, filters, clearFilters, setFilters, type, clientTypes, intakeTypes };
}

