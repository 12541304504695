import {db, firebase} from '../../utilities'
import DB from '../../DAL/DB'

const users = new DB.Users()




export default async function setInspector(project, inspector, returns) {
  if (!inspector || !inspector.id) {
    project.inspection = null
    return
  }

  const calendar = new DB.UserCalendars(inspector.id)

  // check whether the user has changed the inspector; if they have, replace the calendar entry
  const {inspection = {inspector: {}}} = project

  // if the provided inspector is different from the one on the project, then replace the calendar entry with the new user
  if (inspection && inspection.inspector && inspection.inspector.id !== inspector.id) {
    // delete any old calendared inspections for the project
    if (inspection.inspector.id) {
      const {data: appointments, ok} = await calendar.get(null, {filters: [['id', '==', project.id]]})

      //console.log(0)
      if (ok && appointments.length > 0) {
        for (let appointment of appointments) {
          await calendar.delete(appointment.id)
        }
      }
    }
  }

  const {data, ok} = await users.get(inspector.id)

  if (!ok) throw new Error('the new inspector for the project is not in the database; this should be impossible')

  const [{name, id, email = null, phone = null, organization}] = data
  if (inspection && inspection?.date && inspection.inspector && inspection.inspector.id !== inspector.id) {
    let emailR = inspector.email || data[0]?.organization?.email || null;
    let newInspector = { id: inspector.id , name: inspector.name, email: emailR, organization: data[0].organization}
      //console.log('we will inject')
      const {
        inspector: {name, id},
        date: canceledDate,
      } = inspection
      //console.log('purane wala banda',inspection)
      const dateObj = canceledDate.toDate()
      const intakeCancellation = {canceledAt: firebase.firestore.Timestamp.now(), newScheduledDate:  firebase.firestore.Timestamp.fromDate(dateObj), canceledDate: firebase.firestore.Timestamp.fromDate(dateObj), inspector: {name, id}, reason: '', rescheduled: true, newInspector}
      //console.log('intakeCancellation',intakeCancellation)
      project.intakeCancellations = [...(project.intakeCancellations || []), intakeCancellation].filter((i) => i)
  }

  // Build the inspector data to store on the project.
  const inspectorData = { name, id, email, phone, organization };
  if (inspector.third_party_inspector) {
    inspectorData.third_party_inspector = true;
  }


  const findUndefinedKeys = (obj, path = '') => {
    let undefinedKeys = [];
    
    Object.entries(obj).forEach(([key, value]) => {
        const newPath = path ? `${path}.${key}` : key;
        
        if (value === undefined) {
            undefinedKeys.push(newPath);
        } else if (typeof value === 'object' && value !== null) {
            undefinedKeys.push(...findUndefinedKeys(value, newPath));
        }
    });
  
    return undefinedKeys;
  }

  // const undefinedKeys = findUndefinedKeys(project);
  // console.log('undefinedKeys',undefinedKeys);
  // console.log('here it is >>>>',project)



  // Set the project inspection data.
  project.inspection = { ...inspection, inspector: inspectorData };

  console.log("project.inspection :::: ", project.inspection)
  returns.push([calendar.update(project.id, null, project)])
}
