import React, { useEffect, useState, useRef } from 'react';
import { Spin, Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { auth } from '../../Auth';
import ProjectStatusBar from '../ProjectStatusBar';
import get from './get';
import moment from 'moment';
import ProjectFlowGraphs from './ProjectFlowGraphs';
import { firebase } from '../../utilities';

export default function Dashboard() {
  // State for Projects Overview
  const [barFilters, setBarFilters] = useState({
    timespan: moment().subtract(7, 'days'),
  });
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState(null);
  const [query, setQuery] = useState({});

  // State for Clients list
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);

  async function wrappedGet() {
    setLoading(true);
    const [projectsData, q] = await get({ ...query, barFilters });
    setProjects(projectsData);
    setQuery(q);
    setLoading(false);
  }

  function setFilter(k, v) {
    const ff = { ...barFilters };
    ff[k] = v;
    setBarFilters(ff);
  }

  useEffect(() => {
    wrappedGet();
  }, [barFilters]);

  // Fetch client accounts from Firestore
  useEffect(() => {
    async function fetchClients() {
      setLoadingClients(true);
      try {
        const orgId = auth.sunkaizenUser.organization.id;
        const querySnapshot = await firebase
          .firestore()
          .collection('users')
          /* .where('organization.id', '==', orgId) */
          .where('type', '==', 'client')
          .get();
        const clientsData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          // Merge address fields into a single string
          const address = `${data.street_number || ''} ${data.route || ''}, ${data.locality || ''}, ${data.administrative_area_level_1 || ''} ${data.postal_code || ''}`.trim();
          return {
            key: doc.id,
            id: doc.id,
            name: data.name,
            email: data.email,
            company: data.company,
            phone: data.phone,
            address,
          };
        });
        setClients(clientsData);
      } catch (error) {
        console.error('Error fetching clients:', error);
      } finally {
        setLoadingClients(false);
      }
    }
    if (auth.sunkaizenUser && auth.sunkaizenUser.organization) {
      fetchClients();
    }
  }, []);

  // Reusable function for column search properties
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            setSearchText('');
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current && searchInput.current.select(), 100);
      }
    },
  });

  // Define columns for the clients table
  const clientColumns = [
    { 
      title: 'Name', 
      dataIndex: 'name', 
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { 
      title: 'Company', 
      dataIndex: 'company', 
      key: 'company',
      ...getColumnSearchProps('company'),
    },
    { title: 'Phone', dataIndex: 'phone', key: 'phone' },
    { title: 'Address', dataIndex: 'address', key: 'address' },
  ];

  return (
    <div
      className="flex-column align-start full-width"
      style={{ padding: '4rem', background: 'rgb(244, 246, 248)' }}
    >
      <h1 className="page-header">Projects Overview</h1>
      {loading && <Spin size="large" />}
      {projects && (
        <ProjectStatusBar
          auth={auth}
          filters={barFilters}
          projects={Object.values(projects)}
          clearFilters={() => setBarFilters({ timespan: moment().subtract(3, 'months') })}
          setFilters={setFilter}
        />
      )}
      <ProjectFlowGraphs projects={projects} />

      {auth.sunkaizenUser.type === 'admin' && (
        <div className="full-width" style={{ marginTop: '2rem' }}>
          <h4 style={{ textAlign: 'left' }}>Clients</h4>
          {loadingClients ? (
            <Spin />
          ) : (
            <Table
              dataSource={clients}
              columns={clientColumns}
              pagination={{ pageSize: 10 }}
              style={{ width: '100%', backgroundColor: '#fff' }}
            />
          )}
        </div>
      )}
    </div>
  );
}
