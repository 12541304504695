import {image, selectedImage, shape} from './mapIcons'
import {contentString} from '../utilities'

export default function addProjects(state, pins, onClick) {
  const {
    project,
    projects,
    mapping: {map},
  } = state

  for (let i in pins) {
    pins[i].setMap(null)
  }

  const pp = {}

  for (let i in projects) {
    const {id, site = {}} = projects[i]

    const {siteRepEmail: email, siteRepName: contact, siteRepPhone: phone, geocode_results} = site

    const g = geocode_results || {formatted_address: {}, geometry: {location: {}}}
    const {
      formatted_address: title,
      geometry: {location: position},
    } = g

    let icon = id === project.id ? selectedImage() : image()
    const marker = new window.google.maps.Marker({position, title, map, shape, icon})

    const content = contentString({ name: title, contact, phone, email})
    const infoWindow = new window.google.maps.InfoWindow({content})

    marker.addListener('click', () => {
      onClick(projects[id])
      infoWindow.open(map, marker)
    })

    pp[id] = marker
  }

  return pp
}
