import React from 'react';
import CancellationPhoto from '../CancellationPhoto';
import LabeledSection from './LabeledSection';
import moment from 'moment';

export default function CancelledIntakes({ project }) {
  // Make a sorted copy, sorting descending by canceledAt (newest first)
  const sortedCancellations = project.intakeCancellations
    ? project.intakeCancellations.slice().sort((a, b) => b.canceledAt.seconds - a.canceledAt.seconds)
    : [];

  return (
    <div className="flex-column full-width">
      {sortedCancellations.length > 0 && (
        <div>
          <h4>Cancelled Intakes</h4>
          <div>
            {sortedCancellations.map(
              (
                {
                  canceledAt,
                  reason,
                  canceledDate,
                  inspector,
                  comment,
                  photo,
                  rescheduled = false,
                  newScheduledDate = null,
                  newInspector = null,
                },
                i
              ) => (
                <div
                  key={i}
                  className="padding-1 background-white margin-bottom-1"
                  style={{ borderRadius: '1rem', border: '0.1rem solid lightgrey' }}
                >
                  <LabeledSection label={rescheduled ? 'Rescheduled on' : 'Canceled on'}>
                    {moment(canceledAt.toDate()).format('MM/DD/YYYY HH:mm')}
                  </LabeledSection>
                  {!rescheduled && <LabeledSection label="Reason">{reason}</LabeledSection>}
                  {comment && <LabeledSection label="Cancellation Note">{comment}</LabeledSection>}
                  {photo && (
                    <div className="flex-column">
                      <div className="padding-right-1 bold">Cancellation Photo</div>
                      <CancellationPhoto
                        className="full-width margin-1"
                        style={{ height: '10rem', overflow: 'hidden' }}
                        photo={photo}
                      />
                    </div>
                  )}
                  <hr style={{ border: '1px solid rgba(244, 244, 244)' }} />
                  {newScheduledDate && (
                    <LabeledSection label="Now Scheduled For">
                      {moment(newScheduledDate.toDate()).format('MM/DD/YYYY HH:mm')}
                    </LabeledSection>
                  )}
                  <LabeledSection label="Was Scheduled For">
                    {canceledDate && moment(canceledDate.toDate()).format('MM/DD/YYYY HH:mm')}
                  </LabeledSection>
                  {newInspector && newInspector.name ? (
                    <LabeledSection label="Field User">{newInspector.name}</LabeledSection>
                  ) : (
                    <LabeledSection label="Field User">{inspector && inspector.name}</LabeledSection>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}
