import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Loading from './Loading'
import RubricSettings from './RubricSettings'
import RubricSection from './RubricSection'
import TitleBar from './TitleBar'
import {useSubscriptions} from './effects'
import {getFormFields} from './utilities'
import {Collapse, Tabs} from 'antd'
import  AutoRubric  from './AutoRubric'
import './RubricPage.scss'
import { useDispatch } from 'react-redux';

function stop(state) {
  return {
    sections: state.sections, 
    form: state.form, 
    scoringSystems: state.scoringSystems, 
  }
}

export function TopLevelState({sections, form, scoringSystems,  history, match}) {
  const [sectionList, setSectionList] = useState([])
  const [sharedForm, setSharedForm] =  useState(false)

  const dispatch = useDispatch();
  useSubscriptions(match.params.formId)
  // to clear the previous form sections
  useEffect(() => {
    dispatch({ type: 'RESET_SECTIONS' });
    if(form.shared){
      setSharedForm(form.shared)
    }
  }, [form]);

  useEffect(
    function () {
      setSectionList(Object.keys(sections))
    },
    [sections]
  )
  useEffect(
    function () {
      setSectionList(Object.keys(sections))
    },
    [sections]
  )
  useEffect(
    function () {
      getFormFields(form)
    },
    [form.id]
  )

  if (!Object.keys(scoringSystems).length)
    return (
      <div className="padding-2">
        You have not created any scoring systems; you must have at least one scoring system to create a rubric
      </div>
    )

  return (
    <div className="rubric-page full-width flex-column">
      <TitleBar history={history} sharedForm = {sharedForm} setSharedForm={setSharedForm}/>
      <Collapse defaultActiveKey={1}>
        <Collapse.Panel key="1" header={<h3 style={{marginTop: '1rem'}}>Rubric Settings</h3>}>
          <RubricSettings formId={match.params.formId} />
        </Collapse.Panel>
      </Collapse>
      <AutoRubric form={form} formId={match.params.formId} sharedForm = {sharedForm} setSharedForm={setSharedForm}/>
      {!sectionList && <Loading />}
      <Tabs defaultActiveKey="1">
        {sectionList.map((s, i) => {
          const section = sections[s];
          if (!section) return null; // or provide a fallback
          return (
            <Tabs.TabPane tab={section.title || 'Untitled'} key={s}>
              <RubricSection key={s} id={s} selectedRubric={''}/>
            </Tabs.TabPane>
          );
        })}
      </Tabs>

    </div>
  )
}

const WrappedTopLevelState = connect(stop)(withRouter(TopLevelState))
export default WrappedTopLevelState
