import React, {useEffect, useState} from 'react'
import {Spin} from 'antd'
import TopLevelState from './TopLevelState'
import {subscribe} from '../HOC/Firebase/SubscribeHOC'
import { auth } from '../Auth'

function ResourceGetter({match, location, history, makeSubscription, subscribeResources}) {
  // TODO: move this to top level HOC?
  const [error, setError] = useState(null)

  const {id: doc} = match.params
  useEffect(
    function () {
      return makeSubscription({paths: [{collection: 'projects', doc}]}, 'projects', (err) => {
        console.info('makeSubscription error:', err.code)
        setError(err.code)
      })
    },
    [doc]
  )

  const [resource] = subscribeResources['projects'] || []
  // DOE1-547
  useEffect(() => {
    if(resource && resource.user_index && auth.sunkaizenUser) {
      const usersList =  Object.keys(resource.user_index)
      //const allUsers = ['super-admin','admin','client','installer','scheduler','quality-manager','field-user']
      const usersTypeToCheck = ['admin','client','installer','scheduler','quality-manager','field-user']
      // console.log('resources',resource)
      // console.log('auth.sunkaizenUser',auth.sunkaizenUser)
      if( usersTypeToCheck.includes(auth.sunkaizenUser.type)) {
          // console.log('c1',resource.organization.id !== auth.sunkaizenUser.organization.id)
          // console.log('related usersList',usersList)
          // console.log('c2',!usersList.includes(auth.sunkaizenUser.id))
          if (resource.organization.id !== auth.sunkaizenUser.organization.id &&  !usersList.includes(auth.sunkaizenUser.id)) {
            setError('permission-denied')
          }   
      }
    }
  },[resource,auth.sunkaizenUser])

  if (error) {
    if (error == 'permission-denied') {
      return (
        <div className="padding-3">
          <h2>You do not have permission to manage this project.</h2>
        </div>
      )
    } else {
      return (
        <div className="padding-3">
          <h2>Something went wrong, please try reloading the page.</h2>
        </div>
      )
    }
  }

  return resource ? (
    <TopLevelState {...{resource, match, location, history}} />
  ) : (
    <div className="padding-3">
      <Spin size="large" />
    </div>
  )
}

const WrappedResourceGetter = subscribe()(ResourceGetter)
export default WrappedResourceGetter
