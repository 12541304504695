import React, { useEffect, useRef, useState } from 'react'
import embed from 'vega-embed'
import moment from 'moment'
import { auth } from '../../../Auth'
import PerWeek from './PerWeek' // Uses the updated spec for bar graph

// Vega-Embed options for the pie chart
const opts = {}

export default function ProjectFlowGraphs({ test, projects }) {
  const [pieSpec, setPieSpec] = useState(undefined)
  const graph = useRef(null)

  if (!projects) projects = {}

  // Convert projects to an array and adjust timestamp fields.
  projects = Object.values(projects)
    .map((project) => {
      project.updated = moment.unix(project.updated._seconds)
      project.completed_on = project.completed_on
        ? moment.unix(project.completed_on._seconds)
        : undefined
      if (project.created && project.created._seconds) {
        project.created = moment.unix(project.created._seconds)
      }
      return project
    })
    // The Dashboard already filters projects by the desired interval.
    .filter((p) => !['Cancelled', 'Requested'].includes(p.status))

  // Build the pie chart spec.
  useEffect(() => {
    if (projects.length === 0) return
    const dataToUse = test ? test : projects
    const spec = {
      $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
      description: 'A pie chart showing projects by status',
      width: 300,
      height: 300,
      data: { values: dataToUse },
      transform: [
        {
          aggregate: [{ op: 'count', as: 'count' }],
          groupby: ['status'],
        },
      ],
      mark: { type: 'arc', outerRadius: 150 },
      encoding: {
        theta: { field: 'count', type: 'quantitative' },
        color: { field: 'status', type: 'nominal' },
        tooltip: [
          { field: 'status', type: 'nominal', title: 'Status' },
          { field: 'count', type: 'quantitative', title: 'Count' },
        ],
      },
      view: { stroke: null },
    }
    setPieSpec(spec)
  }, [projects.length, test])

  // Embed the pie chart when the spec is ready.
  useEffect(() => {
    (async function () {
      if (!graph.current || !pieSpec) return
      await embed(graph.current, pieSpec, opts)
    })()
  }, [graph, pieSpec])

  return (
    <div className="full-width">
      <div className="full-width flex-row padding-1">
        {/* Updated Weekly Project Flow chart as a bar graph */}
        <PerWeek projects={projects} />
        <div className="background-white padding-1" style={{ flex: 1 }}>
          <h4>Projects in Status</h4>
          <div className="full-width" ref={graph} />
        </div>
      </div>
    </div>
  )
}
