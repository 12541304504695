export default function contentString({ name, contact, phone, email }) {
    return `
      <div>
        ${name !== undefined ? `<div> ${name} </div>` : ''}
        <hr />
        ${contact !== undefined ? `<div><span> Contact: </span> ${contact}</div>` : ''}
        ${phone !== undefined ? `<div><span> Phone: </span> ${phone}</div>` : ''}
        ${email !== undefined ? `<div><span> Email: </span> ${email}</div>` : ''}
      </div>
    `;
  }
  