export default function sorter(sortValue) {
  if (sortValue && sortValue.field && sortValue.order) {
    const direction = sortValue.order === `ascend` ? `asc` : `desc`
    return [
      {
        [`${sortValue.field}${sortValue.field === `created` ? `._seconds` : `.sortable`}`]: direction,
      },
    ]
  }

  // Default sort: created descending
  return [
    {
      'created._seconds': 'desc',
    },
  ]
}
