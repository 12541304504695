/*
    pre-processing and post-processing cannot be async
    preset has to populate tableStrategy with the following:
        filters: the button + dropdown that lets users retrieve saved list filters
        search: renders the searchbar
        tabs: renders the tabs; must include a key to use to get the property for the tabs
        create: component that renders in a modal when add project button is pressed
        bulk: component that renders in a modal when bulk add button is pressed
        columns: instructions for rendering the columns

    these preset requirements are defined in ./strategies/[strategy_name]

    the component will have two props, configuration and preset
        preset will determine the base rules of the table
        configuration allows modifications to that preset?

    <ElasticTable strategy='projects' />
    <ElasticTable strategy='users' />
*/

import React, {useState, useEffect} from 'react'

import {connect} from 'react-redux'
import {Table} from 'antd'
import {onChange} from './onChange'
import {setStrategy, clearQuery, setTabs, setTableControls} from './action_creators'
import FilterMeta from './FilterMeta'
import CancelModal from './CancelModal'
import ContextPopup from './ContextPopup'
import {capitalizeFirst} from '../utilities'
import DB from '../DAL/DB'
import './UnifiedList.css'

const meta = new DB.Meta()

function dToP(dispatch) {
  return {
    // configure: configuration => dispatch(configure(configuration)),
    clearQuery: () => dispatch(clearQuery()),
    setStrategy: (strategy) => dispatch(setStrategy(strategy)),
    setTabs: () => dispatch(setTabs([])),
    setTableControls: (f) => dispatch(setTableControls(f)),
  }
}

function sToP(state) {
  return {
    resources: state.resources,
    tableStrategy: state.tableVars.tableStrategy,
    tableControls: state.tableControls,
  }
}

function ElasticTable({location = {}, strategy = null, configuration, ...props}) {
  const {resources, tableStrategy, tableControls} = props
  const {setStrategy, setTabs, clearQuery} = props // configure,

  const [modalVisible, setModalVisible] = useState(false)
  const [cancelId, setCancelId] = useState(null)
  // const [lastUpdate, setLastUpdate] = useState(null)

  useEffect(
    function () {
      //console.info('Config, Strategy changed!')
      if (strategy) {
        setStrategy(strategy)
        onChange()
      }

      return () => {
        setStrategy('none')
        clearQuery()
        setTabs()
      }
    },
    [configuration, strategy]
  )

  useEffect(
    function () {
      //console.info('Location changed!')
      const {savedFilter: {filters} = {}} = location.state || {}
      if (filters) {
        setTableControls({filters})
        onChange()
      }
    },
    [location.state]
  )

  // Disabled because of so many updates
  // useEffect(
  //   function () {
  //     console.info('tableStrategy changed!')

  //     return meta.subscribe('elasticUpdates', null, function ({ok}) {
  //       if (ok) {
  //         onChange()
  //       }
  //     })
  //   },
  //   [tableStrategy.type]
  // )

  const {rowClassName, rowSelection, onRow, type, columns: columnsFunction = () => null} = tableStrategy || {}
  const {pagination, filters, sorter, loading} = tableControls

  // I don't really know what to do with the cancel modal here; it's not going to show up on all the versions of the table
  const columns = columnsFunction({pagination, filters, sorter}, setModalVisible, setCancelId, onChange)

  return (
    <div className="full-width">
      <h2 className="flex-row align-start">{capitalizeFirst(type || '')}</h2>
      <div className="table-body-wrapper">
        <FilterMeta />
        <ContextPopup />
        {tableStrategy.type === strategy && (
          <Table
            style={{background: 'white'}}
            {...{
              loading,
              pagination,
              columns,
              dataSource: Object.values(resources).map((r) => ({...r, key: r.id})),
              rowSelection,
              onRow,
              onChange,
              rowClassName,
              rowkey: (record) => record.id,
            }}
          />
        )}
        <CancelModal id={cancelId} visible={modalVisible} setModalVisible={setModalVisible} />
      </div>
    </div>
  )
}

const WrappedElasticTable = connect(sToP, dToP)(ElasticTable)
export default WrappedElasticTable
