import {FUNCTIONS_URL} from '../../../../config'
// import DB from '../../../../DAL/DB'
// import convertSectionsAndFields from './convertSectionsAndFields'
// import convertRubrics from './convertRubrics'

// export default async function duplicate (id) {
//     try {
//         const batch = new DB.FirebaseBatch()
//         const fieldIdDict = await convertSectionsAndFields(id, batch)
//         await convertRubrics(id, fieldIdDict, batch)
//         await batch.commit()

//     }
//     catch (e) { console.log("failed to commit duplicate form", e) }
// }

//import convertSectionsAndFields from './convertSectionsAndFields'
//import convertRubrics from './convertRubrics'
import axios from 'axios'
//import { error } from 'console'

export default async function duplicate(id) {
  // try {
  //   const [newId, fieldIdDict] = await convertSectionsAndFields(id)
  //   console.log('successful coping convertSectionsAndFields')
  //   await convertRubrics(id, newId, fieldIdDict)
  // } catch (e) {
  //   console.log('failed to commit duplicate form', e)
  // }

  try {
    const response = await axios.get(`${FUNCTIONS_URL}/utilities/duplicateForm`, { params: { formId: id, copyActions: true } })
    if (response && response.data) {
      return {...response.data}
    }
  } catch (error) {
    console.log('failed to commit duplicate form', error)
    return {status: false, error }
  }
 
}
