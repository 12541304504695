import {auth} from '../../Auth'
import moment from 'moment';

const filterConverter = {
  clients: (clientsArray) => (clientsArray ? {terms: {[`client.id`]: clientsArray}} : {}),
  intakes: (status) => (status ? {term: {'status.sortable': {value: status}}} : {}),
  timespan: (timespan) => (timespan ? {range: {'created._seconds': {gte: timespan.unix()}}} : {}),
  'inspection.inspector.id': (id) => [['inspection.inspector.id', '==', id]],
  'client.id': (id) => [['client.id', '==', id]],
}

export default function makeQuery(query) {
  const { size = 1000, from = 0, query_strings, barFilters } = query;

  console.log("Bar Filters Input:", barFilters);

  const ff = Object.entries(barFilters).map(([name, value]) => {
    console.log("Filter Key:", name, "Filter Value:", value);
    return filterConverter[name](value);
  });

  console.log("Generated Filters:", ff);

  // No hardcoded "last 3 months" filter here
  ff.push({ match: { 'organization.id': auth.sunkaizenUser.organization.id } });

  const or = [
    { match: { 'organization.id': auth.sunkaizenUser.organization.id } },
    { match: { [`user_index.${auth.sunkaizenUser.organization.id}`]: 'client' } },
    { match: { [`user_index.${auth.sunkaizenUser.organization.id}`]: 'inspector' } },
    { match: { [`user_index.${auth.sunkaizenUser.organization.id}`]: 'installer' } },
  ];

  console.log("Final Filters:", ff);

  return {
    type: `projects`,
    size,
    from,
    query_strings,
    filters: ff,
    or,
  };
}