import BaseModel from './BaseModel';
import { db } from '../utilities';

export default class Forms extends BaseModel {
  constructor() {
    super(db.collection('forms'));
  }

  static async get(id: string) {
    try {
      const doc = await db.collection('forms').doc(id).get();
      if (doc.exists) {
        return { data: doc.data() };
      } else {
        console.error("No such document!");
        return { data: null };
      }
    } catch (error) {
      console.error("Error getting document:", error);
      return { data: null };
    }
  }

  static async updateSharedStatus(id: string, isShared: boolean) {
    try {
      await db.collection('forms').doc(id).update({ shared: isShared });
      return { success: true };
    } catch (error) {
      console.error("Error updating shared status:", error);
      return { success: false, error };
    }
  }
  static async getSharedForms() {
    try {
      const querySnapshot = await db.collection('forms').where('shared', '==', true).get();
      const forms = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return { data: forms };
    } catch (error) {
      console.error("Error fetching shared forms:", error);
      return { data: [] };
    }
  }
}
