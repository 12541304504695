export default function makeSpec(projects = []) {
  // Count all projects in the filtered dataset.
  const createdCount = projects.filter(p => p.created).length
  const completedCount = projects.filter(p => p.completed_on).length

  const data = [
    { category: 'Created', count: createdCount },
    { category: 'Completed', count: completedCount },
  ]

  return {
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    description: 'Weekly Project Flow Bar Chart',
    data: { name: 'table', values: data },
    mark: 'bar',
    width: 400,
    height: 300,
    encoding: {
      x: { field: 'category', type: 'nominal', title: 'Project Type' },
      y: { field: 'count', type: 'quantitative', title: 'Count' },
      color: { 
        field: 'category', 
        type: 'nominal',
        scale: {
          domain: ['Created', 'Completed'],
          range: ['#234091', '#4f8335']
        }
      },
      tooltip: { field: 'count', type: 'quantitative', title: 'Count' }
    },
  }
}
