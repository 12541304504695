import { useEffect, useState } from 'react'
import embed from 'vega-embed'
import { useLoggerEffect } from '../../../effects'

const opts = {}

export default function useMakeGraph(graph, projects = [], makeSpec) {
  const [spec, setSpec] = useState(undefined)
  const logger = useLoggerEffect('')

  useEffect(() => {
    // No extra transformation is needed—the projects data is already filtered.
    const generatedSpec = makeSpec(projects)
    setSpec(generatedSpec)
  }, [projects])

  useEffect(() => {
    (async function () {
      if (!graph || !graph.current || !spec) return
      await embed(graph.current, spec, opts)
      if (logger) await logger.log('Updated weekly project flow bar chart')
    })()
  }, [graph, spec, projects, logger])
}
