import React, { useState } from 'react';
import { auth } from '../../Auth';
import { Button, Modal } from 'antd';
import Picker, { pickerMap } from './Picker';

export default function TypeRubric({ id, intake, status, rubric, thirdPartyInspector }) {
  const [show, setShow] = useState(null);

  return (
    <div>
      <div
        className="flex-row margin-2 padding-0"
        style={{ marginBottom: 0, justifyContent: 'space-between' }}
        data-cy="formType"
      >
        <div className="flex-column">
          <div style={{ flex: 1, fontSize: '65%' }}>Project Type</div>
          <div>{intake.title}</div>
        </div>
        {/* Only show Change button if thirdPartyInspector is NOT true */}
        {!thirdPartyInspector && (
          <Button
            disabled={
              status !== 'To Be Scheduled' ||
              ['installer', 'field-user', 'quality-manager', 'scheduler'].includes(auth.sunkaizenUser.type)
            }
            onClick={() => setShow('form')}
            icon="edit"
          >
            Change
          </Button>
        )}
      </div>
      <div className="flex-row rubric-container" data-cy="rubricType">
        <div className="flex-column">
          <div style={{ flex: 1, fontSize: '65%' }}>Project Rubric</div>
          <div>{(rubric || {}).name}</div>
        </div>
        {/* Only show Change button if thirdPartyInspector is NOT true */}
        {!thirdPartyInspector && (
          <Button
            disabled={
              !['To Be Scheduled', 'Scheduled', 'In Progress', 'Review', 'Needs Corrections'].includes(status) ||
              ['installer', 'field-user', 'quality-manager', 'scheduler'].includes(auth.sunkaizenUser.type)
            }
            onClick={() => setShow('rubric')}
            icon="edit"
          >
            Change
          </Button>
        )}
      </div>
      <Modal
        title={show ? pickerMap[show].modalTitle : ''}
        visible={!!show}
        onCancel={() => setShow(null)}
        onOk={() => setShow(null)}
        footer={null}
      >
        {show && <Picker type={show} project={{ id, intake }} done={() => setShow(null)} />}
      </Modal>
    </div>
  );
}
