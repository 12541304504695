// TitleBar.js
import React from 'react'
import { connect } from 'react-redux'
import { saveRubrics } from './utilities'
import { Button, Modal } from 'antd'
import './TitleBar.scss'
import Forms from '../DAL/Forms'
const buttonPadding = { paddingLeft: '5rem', paddingRight: '5rem' }

function mapStateToProps(state) {
  return {
    form: state.form,
    rubrics: state.rubrics,
    sections: state.sections,
    actions: state.actionData.actions,
  }
}

export function TitleBar({ history, form, rubrics, sections, actions, sharedForm , setShareForm }) {
  async function save() {
    if(!sharedForm == form.shared || sharedForm){
      if (!rubrics || Object.keys(rubrics).length === 0) {
        console.log('Error: No rubrics available to save.')
      }
  
      try {
        const firstRubricKey = Object.keys(rubrics)[0]
        const formId = rubrics[firstRubricKey]?.formId
  
        if (!formId) {
          console.error('Error: formId is missing in rubrics.')
        }      
        await Forms.updateSharedStatus(formId, sharedForm)
      }catch{
        console.error('error in updating the shared Form')
      }
    }
    const saved = await saveRubrics(form.id, rubrics, sections, actions, form.enableAutoRubric)
    if (saved) {
      history.push('/forms')
    }
  }

  const handleSave = () => {
    if (sharedForm) {
      Modal.confirm({
        title: 'Confirm Share Form',
        content: 'You have enabled "Share form across all organization". This will make the form available organization-wide. Do you wish to proceed?',
        onOk: async () => {
          await save()
        }
      })
    } else {
      save()
    }
  }

  return (
    <div className="flex-row justify-space-between padding-2 title-bar title-bar-positioning margin-bottom-2">
      <h1 className="padding-0 margin-0 page-title" style={{ marginTop: '3px' }}>
        <strong>Form: </strong>
        {form.title}
      </h1>
      <div className="button-container">
        <Button size="large" onClick={() => history.push(`/forms`)} style={{ marginRight: '2.5rem', ...buttonPadding }}>
          Cancel
        </Button>
        <Button size="large" type="primary" onClick={handleSave} style={{ ...buttonPadding, marginRight: '1.5rem' }}>
          Save and Exit
        </Button>
      </div>
    </div>
  )
}

const WrappedTitleBar = connect(mapStateToProps)(TitleBar)
export default WrappedTitleBar
