import {store} from '../utilities'
import {getLock, unlock} from './locking'
import {lockQueue, setLoading} from '../action_creators'
import get from './get'
import makeQuery from './makeQuery'
import preProcess from './preProcess'
import postProcess from './postProcess'
import {FULL_TEXT_SEARCH_URL} from '../../config'

import { auth } from '../../Auth'

export default async function query() {
  const state = store.getState()
  const lock = getLock()

  if (state.loading) {
    return
  }

  if (!Object.keys(state.resources).length) {
    store.dispatch(setLoading(true))
  }

  try {
    const query = makeQuery()
    preProcess(query)

    
    const must = []

    if(query.type === "reports"){
      if(auth.sunkaizenUser.type === 'field-user' || auth.sunkaizenUser.type === 'quality-manager' || (auth.sunkaizenUser.type === 'scheduler')) {
        if (auth.sunkaizenUser.type === 'field-user') {
          query.query = {
            "match": { 
              "inspection.inspector.id": auth.sunkaizenUser.id
          }
          }
        } 
        else if ((auth.sunkaizenUser.type === 'quality-manager' || auth.sunkaizenUser.type === 'scheduler') && !auth.sunkaizenUser?.clientsAll) {
          const clients = Object.keys(auth.sunkaizenUser.clients)
          //console.log('clients',clients)
          const should = []
          if(clients) {
            clients.forEach(client => {
              should.push(
                    {
                        "match": {
                            [`permissions.${client}`]: "Read"
                        }
                    },
                    {
                        "match": {
                            [`permissions.${client}`]: "Unread"
                        }
                    }
                );
            });
          }
          //console.log('should',should)
          query.query = {
            "bool": {
              "filter": [],
              "must": [],
              "minimum_should_match": 1,
              "should": should
            }
          }
        } 

        else if(auth.sunkaizenUser.type === 'scheduler' && auth.sunkaizenUser?.clientsAll && auth.sunkaizenUser?.organization?.id){
          query.query = {
            "bool": {
              "should": [
                {
                  "bool": {
                    "must": [
                      {
                        "match": {
                          "inspection.inspector.organization.id": auth.sunkaizenUser.organization.id
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "must": [
                      {
                        "match": {
                          [`permissions.${auth.sunkaizenUser.organization.id}`]: "Read"
                        }
                      }
                    ]
                  }
                },
                {
                  "bool": {
                    "must": [
                      {
                        "match": {
                          [`permissions.${auth.sunkaizenUser.organization.id}`]: "Unread"
                        }
                      }
                    ]
                  }
                }
              ],
              "minimum_should_match": 1
            }
          }

        }

        query.sort =  {
          "created._seconds": {
            "order": "desc"
          }
        }
        query.terms = 'reportsApi'
      }  

      

    }

    //console.log('raw query',query)

    const url = `${FULL_TEXT_SEARCH_URL}/full_search`
    const res = await get(url, query)
    console.log('/full_search', FULL_TEXT_SEARCH_URL, query, 'Result', res)

    await postProcess(res, lock)
  } catch (e) {
    console.log('error getting resources from elastic', e)
  } finally {
    if (state.loading) store.dispatch(setLoading(false))
    if (unlock(lock)) {
      store.dispatch(lockQueue(false))
      // queue()
    }
  }
}
