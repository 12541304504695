import {useEffect, useState} from 'react'
import SystemLogger from './SystemLogger'
import {auth} from './Auth'
import DB, {dbResources} from './DAL/DB'
import {db} from './utilities'
import diff from 'deep-diff'

import {useElasticSubscribe} from './effectsFolder'

export {useElasticSubscribe}

export function useLoggerEffect(path) {
  const [logger, setLogger] = useState(null)

  useEffect(
    function () {
      const unsubscribe = auth.sunkaizenUserSubscribe((user) => {
        if (user) {
          const l = new SystemLogger({path, uid: user.id})
          setLogger(l)
        }
      })
      return unsubscribe
    },
    [auth.sunkaizenUser.id]
  )

  return logger
}

export const loggerEffect = (callback, path) => () => {
  // should call callback with a fake logger implementation so that there are no race conditions
  //  when google auth is slow
  auth.sunkaizenUserSubscribe((user) => {
    if (user) {
      const logger = new SystemLogger({path, uid: user.id})
      callback(logger)
    }
  })
}

export const checkLogin = (isLoggedIn, isNotLoggedIn) => () => {
  if (typeof isLoggedIn !== 'function' && typeof isNotLoggedIn !== 'function') {
    throw new Error('at least one arg must be a function')
  }

  return auth.sunkaizenUserSubscribe((user) => {
    if (!user) {
      if (isNotLoggedIn) isNotLoggedIn()
      else return
    } else if (isLoggedIn) isLoggedIn(user)
  })
}

export function useGetResource(collection, id) {
  const [resource, setResource] = useState(null)

  useEffect(
    function () {
      (async function () {
        if (!id) return
        const handle = new dbResources[collection]()
        const {
          data: [r],
          ok,
        } = await handle.get(id)
        if (ok) {
          setResource(r)
        }
      })()
    },
    [id]
  )

  return resource
}

export const simpleGet = (collection, id, callback) => () => {
  const resource = new dbResources[collection]()
  if (![id, collection, callback].every((i) => i)) return
  ;(async function () {
    const {ok, data} = await resource.get(id, null)
    console.log(ok, data)
    if (ok) callback(data[0])
  })()
}

export const getMany = (collection, callback) => () => {
  if (![collection, callback].every((i) => i)) return
  const resource = new dbResources[collection](async function () {
    const {ok, data} = await resource.get()
    if (ok) callback(data)
  })()
}

export function useGetLock(id) {
  const locks = new DB.ProjectLocks()
  const [lock, setLock] = useState(null)

  useEffect(
    function () {
      (async function () {
        await locks.update(id, null, {holder: auth.sunkaizenUser.id})
        setLock(async () => {
          await locks.delete(id)
        })
      })()

      return async () => {
        await locks.delete(id)
      }
    },
    [id]
  )

  return lock
}

export function getLock(id) {
  const locks = new DB.ProjectLocks()
  return useEffect(function () {
    (async function () {
      await locks.update(id, null, {holder: auth.sunkaizenUser.id})
    })()

    return async () => {
      await locks.delete(id)
    }
  }, [])
}

export function checkLock(id, setLock) {
  const locks = new DB.ProjectLocks()
  return useEffect(function () {
    (async function () {
      const {
        data: [lock],
        ok,
      } = await locks.get(id)

      if (!ok) {
        setLock(false)
        return
      }
      if (!lock.holder) {
        setLock(false)
        return
      }
      if (lock.holder === auth.sunkaizenUser.id) {
        setLock(false)
        return
      }

      setLock(true)
    })()
  })
}

class DevChecker {
  devMap = {}
  loading = Promise.resolve(null)

  constructor() {
    this.check = this.check.bind(this)
    this.createSubscription = this.createSubscription.bind(this)

    this.loading = this.createSubscription()
  }

  createSubscription() {
    return new Promise((resolve, reject) => {
      const unsubscribe = db
        .collection('meta')
        .doc('developers')
        .onSnapshot((snap) => {
          unsubscribe()
          const {IDs} = snap.data() || {}
          if (IDs) {
            this.devMap = IDs
            resolve()
          }

          reject()
        })
    })
  }

  async check(id) {
    await this.loading
    if (!this.devMap) console.log('dev checker failed to get developers')
    return this.devMap[id]
  }
}

export function devChecker(id, setValid) {
  const dc = new DevChecker()

  return function () {
    (async function () {
      const valid = await dc.check(id)
      setValid(valid)
    })()
  }
}

export function useDevChecker() {
  const [dev, setDev] = useState(null)
  const dc = new DevChecker()

  useEffect(
    function () {
      (async function () {
        const valid = await dc.check(auth.sunkaizenUser.id)
        setDev(valid)
      })()
    },
    [auth.sunkaizenUser.id]
  )

  return dev
}

export function useDBGet(dbClassInstance, id) {
  const [resource, setResource] = useState([])
  useEffect(
    function () {
      (async function () {
        if (!id) return
        const {
          data: [doc],
          ok,
        } = await dbClassInstance.get(id)

        if (!ok) return
        setResource(doc)
      })()
    },
    [id]
  )

  return resource
}

function validateOptions(options) {
  if (!options) return true
  if (!options.filters) return true

  const valid = options.filters.every((f) => {
    if (!Array.isArray(f)) return false

    let validProp = typeof f[0] === 'string'
    let validOp = typeof f[1] === 'string'
    let validArg = !!f[2]

    //Falsey values are allowed as arguments for the != operator
    // NOTE: be very careful using the != operator; it can very easily result in huge queries -- Chris
    if (f[1] == '!=') validArg = true

    return validProp && validOp && validArg
  })
  return valid
}

export function useDBSubscribe(dbClassInstance, options = {}, callback) {
  const [resources, setResources] = useState([])
  const [previousOptions, setPreviousOptions] = useState({})

  if (!options) {
    options = {}
  }

  const changes = [JSON.stringify(options), JSON.stringify(previousOptions)]

  useEffect(function () {
    if (!validateOptions(options)) {
      throw new Error(`
                options ${JSON.stringify(options)} 
                provided to useDBSubscribe are invalid
            `)
    }
    //console.info('Subscribe to DB:', options)
    const unsubscribe = dbClassInstance.subscribe(null, options, ({data, ok}) => {
      if (!ok) return
      if (callback) return setResources(callback(data))
      setResources(data)
    })

    setPreviousOptions(options)
    return unsubscribe
  }, changes)

  return resources
}

// export function useDBSubscribe (dbClassInstance, options, callback) {
//     const [resources, setResources] = useState([])
//     const [valid, setValid] =  useState(false)

//     // for some reason, checking options in the other useEffect was creating a loop with the code that gets forms in the project builder
//     useEffect(function () {
//         if (!validateOptions(options)) setValid(false)
//         else setValid(true)
//     }, [options])

//     useEffect(function () {
//         if (!valid) return

//         const unsubscribe = dbClassInstance.subscribe(null, options, ({data, ok}) => {
//             if (!ok) return
//             if (callback) return setResources(callback(data))
//             setResources(data)
//         })

//         return unsubscribe
//     }, [valid])

//     return resources
// }

export function useDBSubscribeOne(dbClassInstance, id, callback) {
  const [resource, setResource] = useState(null)

  useEffect(function () {
    const unsubscribe = dbClassInstance.subscribe(id, null, ({data: [doc], ok}) => {
      if (!ok) return
      if (callback) return setResource(callback(doc))
      setResource(doc)
    })

    return unsubscribe
  }, [])

  return resource
}
