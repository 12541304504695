import {initializeProject} from './initializeProject'
import {auth} from '../../Auth'
import checkFormIntegrity from './checkFormIntegrity'

export async function prepareProject(project, first, logger) {
  if (!project.startedAt) {
    await initializeProject(project)
    logger.categoryId = project.id
    const userDisplayName = auth.currentUser.displayName;
    logger.event(
      userDisplayName ? `${userDisplayName} started an intake` : 'Intake started',
      JSON.stringify(project, null, 2),
      3
    );
    return {}
  }

  const data = {}

  if (['Requested', 'To Be Scheduled', 'Scheduled'].includes(project.status)) {
    data.status = 'In Progress'
    project.status = data.status
  }

  let userIsFieldUser = false
  if (['Review'].includes(project.status)) {
    const {organization = {}} = project.inspection.inspector
    if (
      auth.sunkaizenUser.organization.id === organization.id &&
      auth.sunkaizenUser.organization.id !== project.organization.id
    ) {
      userIsFieldUser = true
    } else {
      switch (auth.sunkaizenUser.type) {
        case 'field-user':
          userIsFieldUser = true
          break
        case 'quality-manager':
        case 'admin':
        case 'client':
        case 'super-admin':
          if (first) {
            logger.categoryId = project.id
            const userDisplayName = auth.sunkaizenUser.name;
            logger.event(
              userDisplayName ? `${userDisplayName} started reviewing an intake` : 'Intake Review started',
              null,
              5
            );
            data.reviewer = {
              name: auth.sunkaizenUser.name,
              id: auth.sunkaizenUser.id,
              email: auth.sunkaizenUser.email,
              type: auth.sunkaizenUser.type,
            }
            project.reviewer = data.reviewer
          }
          break
        default:
          console.log('unexpected user accessed this project in review', auth.sunkaizenUser)
      }
    }
  }

  let fields = {[project.id]: {images: project.photos}}
  Object.values(project.intake.sections).forEach((section) => (fields = {...fields, ...section?.fields}))
  await checkFormIntegrity(project, data)

  return {project, fields, userIsFieldUser}
}
