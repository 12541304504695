import React from 'react'
import {
  TagsOutlined,
  HomeOutlined,
  CheckSquareOutlined,
  UserOutlined,
  LineChartOutlined,
  CalendarOutlined,
  BarChartOutlined,
  BarsOutlined,
  ScheduleOutlined,
  AuditOutlined,
} from '@ant-design/icons'
import {Link} from 'react-router-dom'
import projectsContent from './projectsContent'
import {Menu, Popover} from 'antd'

export default function menuItems(ticketSearch) {
  return {
    home: (
      <Menu.Item key="home">
        <Link to="/">
          <HomeOutlined /> My Home
        </Link>
      </Menu.Item>
    ),
    projects: (
      <Menu.Item key="projects">
        <Popover content={projectsContent} trigger="hover" placement="right">
          <Link to="/projects">
            <CheckSquareOutlined /> Projects
          </Link>
        </Popover>
      </Menu.Item>
    ),
    users: (
      <Menu.Item key="users">
        <Link to="/users">
          <UserOutlined /> Users
        </Link>
      </Menu.Item>
    ),
    clients: (
      <Menu.Item key="clients">
        <Link to="/clients">
          <UserOutlined /> Clients
        </Link>
      </Menu.Item>
    ),
    reports: (
      <Menu.Item key="reports">
        <Link to="/reports">
          <LineChartOutlined /> Reports
        </Link>
      </Menu.Item>
    ),
    calendar: (
      <Menu.Item key="calendar">
        <Link to="/calendar">
          <CalendarOutlined /> Calendar
        </Link>
      </Menu.Item>
    ),
    data: (
      <Menu.Item key="data">
        <Link to="/analytics">
          <BarChartOutlined /> Data
        </Link>
      </Menu.Item>
    ),
    // "logs": <Menu.Item key="logs" ><Link to="/logs"><Icon type="table" /> Application Logs</Link></Menu.Item>,
    forms: (
      <Menu.Item key="forms">
        <Link to="/forms">
          <BarsOutlined /> Forms
        </Link>
      </Menu.Item>
    ),
    schedule: (
      <Menu.Item key="schedule">
        <Link to="/schedule">
          <ScheduleOutlined /> Scheduling
        </Link>
      </Menu.Item>
    ),
    scoring: (
      <Menu.Item key="scoring">
        <Link to="/scoringSystems">
          <AuditOutlined /> Scoring Systems
        </Link>
      </Menu.Item>
    ),
    //If the logged in user has any outstanding tickets, then turn the
    // tickets icon red
    tickets: (
      <Menu.Item key="tickets">
        <Link to="/tickets">
          {ticketSearch && ticketSearch.empty ? (
            <div>
              <TagsOutlined /> Tickets
            </div>
          ) : (
            <div>
              <TagsOutlined style={{color: `red`}} /> Tickets
            </div>
          )}
        </Link>
      </Menu.Item>
    ),
  }
}
