import React, {useReducer, useEffect} from 'react'
import {convertPaths} from './utilities'
import {auth} from '../../Auth'

function reducer(state, action) {
  switch (action.type) {
    case `subscription`:
      return {
        ...state,
        subscriptions: {...state.subscriptions, [action.name]: action.value},
        newResources: action.name,
      }
    case `clearUpdate`:
      return {...state, newResources: null}
    case `unsubscriptions`:
      return {...state, unsubscriptions: {...state.unsubscriptions, [action.name]: action.value}}
    case `unsubscribe`:
      const unsubscriptions = {...state.unsubscriptions}
      delete unsubscriptions[action.value]
      return {...state, unsubscriptions}
    default:
      return state
  }
}

export const subscribe = () => (Component) => {
  function SubscribeHOC({childRef, ...props}) {
    const [state, dispatch] = useReducer(reducer, {subscriptions: {}, unsubscriptions: {}, newResources: null})

    const removeSubscription = (name) => {
      // const subs = {...state.unsubscriptions}
      if (state.unsubscriptions[name]) {
        state.unsubscriptions[name]()
        dispatch({type: `unsubscribe`, value: name})
      } else console.log(`the subscription does not exist, and cannot be removed: `, name)
    }

    function clearUpdate() {
      dispatch({type: `clearUpdate`})
    }

    function makeSubscription({paths, options = {}}, name, onError) {
      if (!auth.sunkaizenUser.id) {
        console.log('canceling attempt to access database when user is not logged in')
        return
      }
      if (state.unsubscriptions[name]) state.unsubscriptions[name]()

      const {resource, doc} = convertPaths(paths)

      options.filters = (options.where || []).map(({field, test, value}) => [field, test, value])
      delete options.where
      //console.info('Subscribe to:', paths)

      // console.log(resource, doc, options)
      const unsubscribe = resource.subscribe(
        doc,
        options,
        ({data}) => {
          // if (ok)
          dispatch({type: `subscription`, name, value: data})
          // else console.log(`Error subscribing to db resource: ${[name, JSON.stringify(paths), JSON.stringify(options)]}`)
        },
        (err) => {
          console.info('Subscribe error:', paths, err)
          console.error(err)
          if (onError) {
            onError(err)
          }
        }
      )

      dispatch({type: `unsubscription`, name, value: unsubscribe})
      return unsubscribe
    }

    useEffect(
      function () {
        for (let k in props.subscriptions) {
          makeSubscription(props.subscriptions[k], k)
        }
        return () => Object.values(state.unsubscriptions).map((u) => u())
      },
      [props.subscriptions]
    )

    return (
      <Component
        ref={childRef}
        {...props}
        subscribeResources={(state || {}).subscriptions}
        newResources={state.newResources}
        makeSubscription={makeSubscription}
        removeSubscription={removeSubscription}
        clearUpdate={clearUpdate}
      />
    )
  }

  return React.forwardRef((props, ref) => <SubscribeHOC {...props} childRef={ref} />)
}
