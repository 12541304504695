import React, {useReducer} from 'react'

export function reducer(state, action) {
  switch (action.type) {
    case 'inspectors':
      return {...state, inspectors: action.value}
    case 'setSchedule':
      return {...state, schedule: action.value}
    case 'setFilteredInspectors':
      return {...state, mapping: {...state.mapping, filteredInspectors: action.value}}
    case 'setMap':
      return {...state, mapping: {...state.mapping, map: action.value}}
    case 'calendar':
      return {...state, calendar: action.value}
    case 'projects':
      return {...state, projects: action.value}
    case 'availability':
     // console.log(action)
      return {...state, availability: action.value}
    case 'inspectorAvailability':
      return {...state, inspectorAvailability: action.value}
    case 'pushData':
      return {...state, ...action.value}
    case 'addNote':
      const q = {...state.project}
      if (!q.siteNotes) q.siteNotes = []
      q.siteNotes.push(action.value)
      return {...state, project: q}
    case 'changeNote':
      const p = {...state.project}
      const notes = [...(state.project.siteNotes || [])]
      notes.splice(action.index, 1, action.value)
      p.siteNotes = notes
      return {...state, project: p}
    case 'updateProjects':
      return {...state, updateProjects: !state.updateProjects}
    default:
      return state
  }
}

export const initialState = {
  projects: {},
  inspectors: {},
  availability: {},
  calendar: [],
  time: null,
  date: null,
  duration: null,
  project: {},
  inspector: {},
  projectQuery: {},
  inspectorQuery: {},
  inspectorAvailability: {},
  mapping: {
    map: null,
    filteredInspectors: {},
  },
  updateProjects: false,
}

export const ReducerContext = React.createContext(null)

export const wrap = (C) => (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [scheduledState, scheduledDispatch] = useReducer()

  return (
    <ReducerContext.Provider value={{state, dispatch}}>
      <C {...props} />
    </ReducerContext.Provider>
  )
}
