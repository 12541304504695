import makeQuery from './makeQuery';
import { FULL_TEXT_SEARCH_URL } from '../../config';

export default async function getLastProjects(q) {
  const body = makeQuery(q); // "Last 3 months" logic is already handled in makeQuery

  console.log("Request Body for Last Projects:", JSON.stringify(body));
  console.log(`API URL: ${FULL_TEXT_SEARCH_URL}`);

  try {
    const response = await fetch(`${FULL_TEXT_SEARCH_URL}/full_search`, {
      method: `POST`,
      mode: `cors`,
      headers: { 'Content-Type': `application/json` },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      console.error("Error fetching data:", response.statusText);
      throw new Error("Failed to fetch data.");
    }

    const res = await response.json();
    console.log("API Response for Last Projects:", JSON.stringify(res).length);

    const resources = {};
    const hits = res.hits?.hits || [];
    hits.forEach((hit) => {
      const resource = hit._source;
      if (resource.created) {
        // Optionally handle timestamps here
        //resource.created = new firebase.firestore.Timestamp(resource.created._seconds, resource.created._nanoseconds);
      }
      resources[resource.id] = resource;
    });

    const total = res.hits?.total?.value || 0;

    return [resources, { total }];
  } catch (error) {
    console.error("Error in `getLastProjects` function:", error.message);
    throw error;
  }
}