import React, { useEffect, useState } from 'react';
import { history } from '../../history';
import { auth } from '../../Auth';
import { Button, Modal, Input } from 'antd';
const { TextArea } = Input;
import { connect } from 'react-redux';
import {
  highlightErrors,
  reviewMode,
  setStatus,
  setLoading,
  updateProgress
} from '../action_creators';
import { saveIntake } from '../utilities';
import { loggerEffect } from '../../effects';

// Make sure this import is correct for your Firebase client SDK
import { firebase } from '../../utilities';

import IntakeStatus from './IntakeStatus';
import ReviewOptions from './ReviewOptions';
import ActiveSectionDisplay from './ActiveSectionDisplay';
import { statusLogs } from './statusLogs';

const mapStateToProps = (state) => ({
  project: state.project,
  corrections: Object.values(state.project.intake.corrections || {}).filter((i) => i !== undefined).length,
  reviewMode: state.meta.reviewMode,
});

const mapDispatchToProps = (dispatch) => ({
  highlightErrors: (errors) => {
    dispatch(highlightErrors(errors));
  },
  toggleReviewMode: (bool) => dispatch(reviewMode(bool)),
  setStatus: (status) => dispatch(setStatus(status)),
  setLoading: (bool) => dispatch(setLoading(bool)),
  updateProgress: () => dispatch(updateProgress()),
});

export function Details({
  project,
  corrections,
  reviewMode,
  highlightErrors,
  setStatus,
  setLoading,
  toggleReviewMode,
  updateProgress,
}) {
  const [logger, setLogger] = useState(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [loadingTemp, setLoadingTemp] = useState(false);

  // New state for revert modal, comment, and error message
  const [revertModalVisible, setRevertModalVisible] = useState(false);
  const [revertComment, setRevertComment] = useState('');
  const [revertError, setRevertError] = useState('');

  useEffect(loggerEffect(setLogger, `/projects/${project.id}`), []);

  useEffect(() => {
    updateProgress();
  }, [project.intake.fields, project.intake.sections, project.intake.order, updateProgress]);
  
  async function finalize(done, status) {
    logger.categoryId = project.id;

    console.info(`Finalize done:${done} status: ${status}`);
    try {
      if (!done && status === 'Review') {
        setStatus('Needs Corrections');
      }

      setLoadingTemp(true);
      // Save the intake form (finish flag may trigger extra validation)
      await saveIntake(done);

      // Call the appropriate status log based on the new status
      const newStatus = project.intake.skipReview && project.status === 'In Progress' ? 'Finalized' : status;
      //console.log(' DOE1-482-REOPEN  newStatus',newStatus,corrections) 
      await statusLogs[newStatus](logger, project.site.siteAddress, corrections);

      history.push('/projects');
    } catch (e) {
      setLoadingTemp(false);
      console.log(e);
      highlightErrors(e.errors);
      logger.event(
        `You must fill out all required fields to submit this project; missing fields have been highlighted`,
        null,
        2,
        { [auth.sunkaizenUser.id]: auth.sunkaizenUser.type }
      );
      return;
    } finally {
      setLoading(false);
    }
  }

  const submitReview = () => {
    setConfirmModalVisible(false);
    console.log('project.status ::: ' + project.intake.skipReview);

    // If skipReview is true and status is 'In Progress', treat it as 'Finalized'
    const finalStatus = project.intake.skipReview && project.status === 'In Progress' ? 'Finalized' : project.status;

    if (project && project.rubric && project.rubric !== 'Auto-Rubric') {
      finalize(true, finalStatus);
    } else {
      logger.event(
        `You must fill out all required fields to submit this project; missing fields have been highlighted`,
        null,
        2,
        { [auth.sunkaizenUser.id]: auth.sunkaizenUser.type }
      );
    }
  };

  const handleSubmitReview = () => {
    setConfirmModalVisible(true);
  };

  const handleCancelSubmit = () => {
    setConfirmModalVisible(false);
  };

  const handleSave = () => {
    saveIntake();
  };

  const handleSaveAndExit = () => {
    saveIntake().then(() => history.push(`/projects/${project.id}/manage`));
  };

  // REVERT PROJECT 
  const openRevertModal = () => {
    setRevertModalVisible(true);
  };

  const handleRevertCancel = () => {
    setRevertModalVisible(false);
    setRevertError('');
  };

  async function addHistoryRecordForRevert(projectData, comment) {
    const docRef = firebase
      .firestore()
      .collection('projectTransactionLog')
      .doc();

    const siteInfo = {
      siteOwnerName: projectData.site?.siteOwnerName || '',
      siteAddress: projectData.site?.siteAddress || '',
    };

    const actionDoneBy = {
      inspector: projectData?.inspection?.inspector?.id || '',
      installer: projectData?.installer?.id || '',
      reviewer: projectData?.reviewer?.id || '',
      client: projectData?.client?.id || '',
    };
    const logMessage = 'Status reverted to Review with comments : ' + comment;
    const historyData = {
      actionDoneBy,
      category: 'projects',
      categoryId: projectData.id,
      comment,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      extended: ``,
      message: logMessage,
      newStatus: 'Revert',
      severity: 4,
      site: siteInfo,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      type: 'log',
      updatedBy: auth.sunkaizenUser.id,
      updatedByName: auth.sunkaizenUser.name,
    };

    return docRef.set(historyData);
  }

  /**
   * Called when user clicks "OK" on the Revert modal.
   * Updates status -> "Review", saves project, adds a new history record.
   */
  const handleRevertProject = async () => {
    if (!revertComment.trim()) {
      setRevertError('Please enter a comment for reverting the project status.');
      return;
    }
    setRevertError('');
    setRevertModalVisible(false);
    setLoadingTemp(true);

    // Mark status as "Review"
    project.status = 'Review';
    setStatus('Review');

    try {
      // Save updated project without finish validations
      await saveIntake(false);
      const userName = auth.currentUser?.displayName;
      const message = userName
        ? `${userName} reverted the status from Completed to Review. Comment: ${revertComment}`
        : `Status reverted from Completed to Review. Comment: ${revertComment}`;

      await logger.event(
        message,
        null,
        3,
        { [auth.sunkaizenUser.id]: auth.sunkaizenUser.type }
      );
      
      // Add a new document to the transaction log
      await addHistoryRecordForRevert(project, revertComment);

      history.push('/projects');
    } catch (e) {
      setLoadingTemp(false);
      console.log(e);
      highlightErrors(e.errors);
      logger.event(
        `Failed to revert project. Please ensure all required fields are filled.`,
        null,
        2,
        { [auth.sunkaizenUser.id]: auth.sunkaizenUser.type }
      );
      return;
    } finally {
      setLoading(false);
    }
  };

  // --------------------- RENDER ---------------------
  const { siteOwnerName, siteAddress } = project.site || {};

  return (
    <div className="padding-bottom-1" data-cy="intakeDetails">
      <div className="background-white">
        <IntakeStatus status={project.status} />
        <div className="intake-details-box padding-2">
          <p>
            <strong>Site Owner Name:</strong> {siteOwnerName || 'N/A'}
          </p>
          <p>
            <strong>Property Address:</strong> {siteAddress || 'N/A'}
          </p>
        </div>
        <ActiveSectionDisplay />
        <div
          className="flex-row justify-space-between"
          style={{ borderBottom: '0.1rem solid #DFE3E8', padding: '1.5rem' }}
        >
          <div className="bold">Started</div>
          <div>{project.startedAt && project.startedAt.toDate().toDateString()}</div>
        </div>
        {!loadingTemp && (
          <div className="padding-2">
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', columnGap: '20px' }}>
            {['In Progress', 'Needs Corrections', 'Review'].includes(project.status) && (
              <>
                <Button
                  onClick={handleSave}
                  style={{ marginRight: '1rem' }}
                  type={project.status === 'Review' ? 'primary' : 'default'}
                >
                  Save
                </Button>
                {project.status !== 'Review' && (
                  <Button onClick={handleSaveAndExit} style={{ marginRight: '1rem' }} type="primary">
                    Save and Exit
                  </Button>
                )}
                {['In Progress', 'Needs Corrections'].includes(project.status) && (
                  <Button onClick={handleSubmitReview} type="primary">
                    {project.intake.skipReview ? 'Finalize' : 'Submit for Review'}
                  </Button>
                )}
              </>
            )}
            {project.status === 'Review' &&
              ['super-admin', 'admin', 'quality-manager', 'client'].includes(auth.sunkaizenUser.type) && (
                <ReviewOptions
                  {...{
                    reviewMode,
                    toggleReviewMode,
                    corrections,
                    status: project.status,
                    finalize,
                  }}
                />
              )}
            {project.status === 'Completed' && (
              <Button onClick={openRevertModal} type="danger" style={{ marginLeft: '1rem' }}>
                Revert Project
              </Button>
            )}
            </div>
          </div>
        )}
      </div>
      
      {/* Modal for "Submit for Review" Confirmation */}
      <Modal
        title="Confirm Submission"
        visible={confirmModalVisible}
        onOk={submitReview}
        onCancel={handleCancelSubmit}
      >
        <p>
          {project.intake.skipReview
            ? 'Are you sure you want to finalize this form'
            : 'Are you sure you want to submit for review'}
          ?
        </p>
      </Modal>

      {/* Modal for Revert Project */}
      <Modal
        title="Revert Project"
        visible={revertModalVisible}
        onOk={handleRevertProject}
        onCancel={handleRevertCancel}
      >
        <p>Please provide a reason for reverting the project:</p>
        <TextArea
          value={revertComment}
          onChange={(e) => setRevertComment(e.target.value)}
          rows={4}
          placeholder="Enter your comments here..."
        />
        {revertError && <div style={{ color: 'red', marginTop: '5px' }}>{revertError}</div>}
      </Modal>
    </div>
  );
}

const WrappedDetails = connect(mapStateToProps, mapDispatchToProps)(Details);
export default WrappedDetails;
