import React, {useState, useContext} from 'react'
import {Button, Modal, Progress,  Select} from 'antd'
import {ReducerContext} from './reducer'
import convert from './convert'
import getProjects from './getProjects'
import {saveAs} from 'file-saver'
import useResources from './useResources'
import DateItem from './DateItem'

export default function TopLevelState() {
  const [show, setShow] = useState(false)
  const {state, dispatch} = useContext(ReducerContext)
  const [loader, setLoader] = useState(false)
  const [percent, setPercent] = useState(0)

  function cancel() {
    dispatch({type: 'clear'})
    setShow(false)
    setPercent(0)
    setLoader(false)
  }

  function filterProjects(projects, filters) {
    return projects.filter(project => {
        // Convert Firestore timestamps to JavaScript Date objects
        const createdTimestamp = project.created ? project.created.toDate() : null;
        const updatedTimestamp = project.updated ? project.updated.toDate() : null;

        // Extract filters
        const { createdBefore, createdAfter, updatedBefore, updatedAfter } = filters;

        // Apply filters conditionally
        if (createdBefore && createdTimestamp && createdTimestamp >= createdBefore) return false;
        if (createdAfter && createdTimestamp && createdTimestamp <= createdAfter) return false;
        if (updatedBefore && updatedTimestamp && updatedTimestamp >= updatedBefore) return false;
        if (updatedAfter && updatedTimestamp && updatedTimestamp <= updatedAfter) return false;

        return true;
    });
  }

  async function finalize() {
      const {rubric, form, client, created, updated} = state
      
      if (!form) {
        alert('Select a Form')
        setPercent(0)
        setLoader(false)
        return
      }
      if (!rubric) {
        alert('Select a Rubric')
        setPercent(0)
        setLoader(false)
        return
      }

      setLoader(true)
      setPercent(10)
      const {data: projects} = await getProjects(form, rubric, client, created, updated)
    

      const filteredProjects = filterProjects(projects, 
      { createdBefore: state.created.before , 
        createdAfter: state.created.after, 
        updatedBefore: state.updated.before, 
        updatedAfter: state.updated.after  
      })
      
      //console.log('after filter',filteredProjects, filteredProjects.length)  
      
      if(filteredProjects.length === 0) {
        alert('no project found in the range')
        setPercent(0)
        setLoader(false)
        return 
      }

      setPercent(90)
      console.log('filteredProjects>',filteredProjects)

      const blob = convert(filteredProjects, rubric, form)
      saveAs(blob, 'scoring_report.csv')
      setPercent(100)
      cancel()
  }

  // loads the user's organization's forms into state
  // useForms()
  // useRubrics(state.form)
  useResources()

  //console.log(state)

  return (
    <div>
      <Button onClick={() => setShow(true)} size="small">
        Create Scoring Report
      </Button>
      <Modal
        visible={show}
        onOk={finalize}
        onCancel={cancel}
        title={<h3>Create Scoring Report</h3>}
        width="80rem"
        okText="Generate"
        destroyOnClose={true}
      >
        <div className="padding-bottom-2">
          <div className="padding-bottom-2 full-width">
            <Select
              style={{width: '100%'}}
              placeholder="Select a Form"
              onChange={(value) => dispatch({type: 'form', value})}
            >
              {state.forms.map((f) => (
                <Select.Option value={f.id}>{f.title}</Select.Option>
              ))}
            </Select>
          </div>
          {state.forms.length > 0 && (
            <div className="padding-bottom-2">
              <Select
                style={{width: '100%'}}
                placeholder="Select a Rubric"
                onChange={(value) => dispatch({type: 'rubric', value})}
              >
                {state.rubrics.map((r) => (
                  <Select.Option value={r.id}>{r.name}</Select.Option>
                ))}
              </Select>
            </div>
          )}
          {/* client */}
          {state.clients.length > 0 && (
            <div className="padding-bottom-2">
              <Select
                style={{width: '100%'}}
                placeholder="Select a Client"
                onChange={(value) => dispatch({type: 'client', value})}
              >
                {state.clients.map((c) => (
                  <Select.Option value={c.id}>{c.name}</Select.Option>
                ))}
              </Select>
            </div>
          )}
          <DateItem type="created" />
          <DateItem type="updated" />
        </div>
        {loader && <Progress percent={percent} size="small" />}
      </Modal>
    </div>
  )
}
