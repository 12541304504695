import React, { useRef } from 'react'
import useMakeGraph from './useMakeGraph'
import makeSpec from './makePerWeekSpec'  // Updated spec function

export default function PerWeek({ projects }) {
  const ref = useRef(null)
  useMakeGraph(ref, projects, makeSpec)
  return (
    <div className="background-white margin-right-2 padding-1">
      <h4>Weekly Project Flow</h4>
      <div className="full-width" ref={ref} />
    </div>
  )
}
