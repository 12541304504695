import SystemLogger from '../../SystemLogger'
import {auth} from '../../Auth'
import moment from 'moment'

export default class ValidateScheduling {
  constructor() {
    this.logger = new SystemLogger({ uid: auth.currentUser.uid });
    // Toggle debugging messages
    this.log = true;
  }

  validate({ time, date, duration, project, inspector }, final) {
    let logger = final ? this.logger.event : (log) => console.log();
    if (!this.log) logger = () => null;

    // Only enforce time when final is true
    if (!time && final) {
      logger('You must provide a time for this inspection');
      return false;
    }

    if (!date) {
      logger('You must provide a date for this inspection');
      return false;
    }
    if (!duration && final) {
      logger('You must provide a duration for this inspection');
      return false;
    }
    if (duration && duration.hours < 1 && duration.minutes < 30) {
      logger('This inspection is scheduled for less than an half an hour');
      return false;
    }
    if (!project.id) {
      logger('You have not chosen a project for inspection');
      return false;
    }
    if (!inspector.id) {
      logger('You have not chosen an inspector for this inspection');
      return false;
    }
    return true;
  }
}

