import React, { useEffect, useContext } from 'react';
import { ReducerContext } from '../reducer';
import { TimeChooser, disabledDate, dateCellRender } from '.';
import { useMakeCalendar, useGetAvailability, useAvailabilityFilter } from './effects';
import { pushData } from '../utilities';
import { Calendar } from 'antd';
import moment from 'moment';

export default function DateTimePicker() {
  const { state, dispatch } = useContext(ReducerContext);
  const push = pushData({ state, dispatch });

  const {
    inspectorAvailability: availability,
    date,
    calendar,
    project: { restrict_schedule },
    inspector, // current selected field user
  } = state;

  useAvailabilityFilter();
  useGetAvailability();
  useMakeCalendar();

  // When the selected inspector changes, reset the calendar selection and set a flag.
  useEffect(() => {
    push({ date: null, time: null, dateReset: true });
  }, [inspector && inspector.id]);

  function getDisabledDates() {
    if (restrict_schedule) return disabledDate(availability, date || moment());
    return (currentDate) => {
      // Do not disable past dates.
      return false;
    };
  }

  const restricted = restrict_schedule && inspector && inspector.id && !availability;

  return (
    <div>
      {inspector && inspector.id && (
        <div className="calendar-wrapper">
          <Calendar
            // Use a controlled value. If date is null, fall back to moment() to avoid errors.
            value={date ? date : moment()}
            disabledDate={getDisabledDates()}
            onChange={(e) => push({ time: null, date: e, dateReset: false })}
            dateCellRender={dateCellRender(calendar)}
            // Add a class to override selected styling when reset.
            className={state.dateReset ? 'reset-calendar' : ''}
            fullscreen={false}
          />
        </div>
      )}
      {restricted && <div className="padding-1">Selected Field User has no availability this month</div>}
      {date && <TimeChooser {...{ inspector }} />}
    </div>
  );
}
