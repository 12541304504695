// ClientsPage.js
import React, { useEffect, useState, useRef } from 'react';
import { Spin, Table, Input, Button } from 'antd';
import { SearchOutlined, email,PhoneOutlined } from '@ant-design/icons';
import { auth } from '../../Auth';
import { firebase } from '../../utilities';

export default function Clients() {
  console.log("Clients component rendered");
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);

  // Reusable search properties for columns
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
          icon="SearchOutlined"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            setSearchText('');
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current && searchInput.current.select(), 100);
      }
    },
  });

  // Define table columns according to the updated requirements
  const clientColumns = [
    {
      title: 'Company Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Contact',
      key: 'contact',
      render: (_, record) => (
        <div>
          <div>{record.email}</div>
          <div>{record.phone}</div>
        </div>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (created) => {
        if (created && created.seconds) {
          const date = new Date(created.seconds * 1000);
          return date.toLocaleDateString();
        }
        return '';
      },
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles) => (roles ? roles.join(', ') : ''),
    },
    {
      title: 'Third Party Inspector',
      dataIndex: 'third_party_inspector',
      key: 'third_party_inspector',
      render: (inspector) => (inspector ? 'Yes' : 'No'),
    },
  ];

  // Fetch organizations from Firestore ordered by created (latest first)
  useEffect(() => {
    async function fetchOrganizations() {
      setLoadingClients(true);
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection('organizations')
          .orderBy('created', 'desc')
          .get();
        const orgData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            key: doc.id,
            id: doc.id,
            name: data.name,
            email: data.email,
            phone: data.phone,
            address: data.address,
            created: data.created,
            roles: data.roles,
            third_party_inspector: data.third_party_inspector,
          };
        });
        setClients(orgData);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      } finally {
        setLoadingClients(false);
      }
    }

    if (auth.sunkaizenUser) {
      fetchOrganizations();
    }
  }, []);

  return (
    <div
      className="flex-column align-start full-width"
      style={{ padding: '4rem', background: 'rgb(244, 246, 248)' }}
    >
      <h1 className="page-header">Clients</h1>
      {loadingClients ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={clients}
          columns={clientColumns}
          pagination={{ pageSize: 10 }}
          style={{ width: '100%', backgroundColor: '#fff' }}
        />
      )}
    </div>
  );
}
