export default function changeAddress(user, address) {
  //Create lookup for what "types" of address info we want to store onto a user's record
  const types = {
    street_number: true,
    route: true,
    locality: true,
    administrative_area_level_1: true,
    postal_code: true,
  }

  if (!address || !Array.isArray(address)) {
    for (let i in types) {
      types[i] = null
    }
    return {...user, ...types, address, geocode_results: null}
  }

  //Filter down just to the address components we want from the lookup
  const addressComponents = address[0].address_components.filter((c) => types[c.types[0]])
  for (let i in types) {
    types[i] = addressComponents.filter((c) => c.types.includes(i))[0]?.short_name || null 
  }

  return {...user, ...types, address: address[0].formatted_address, geocode_results: address[0]}
}
