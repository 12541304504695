import { firebase } from '../../utilities'  // Adjust the path as needed

/**
 * Fetch rubrics for all given form IDs.
 * 
 * @param {Array<string>} formIds - Array of formId strings.
 * @returns {Promise<Object>} - Resolves to an object mapping formId => array of rubric docs.
 */
export async function fetchRubricsByFormIds(formIds) {
  if (!formIds || formIds.length === 0) return {};

  const result = {};
  const batchSize = 10; // Firestore "in" query limit
  const batches = [];

  // Split formIds into batches of up to 10
  for (let i = 0; i < formIds.length; i += batchSize) {
    batches.push(formIds.slice(i, i + batchSize));
  }

  for (const batch of batches) {
    const snapshot = await firebase
      .firestore()
      .collection('rubrics')
      .where('formId', 'in', batch)
      .get();

    snapshot.forEach((doc) => {
      const data = doc.data();
      const formId = data.formId;
      if (!result[formId]) {
        result[formId] = [];
      }
      result[formId].push(data);
    });
  }

  return result;
}
