import React from 'react'
import {Button} from 'antd'
import {auth} from '../../Auth'
import {connect} from 'react-redux'

const stop = (state) => ({project: state.project})

export function ReviewOptions({project, toggleReviewMode, reviewMode, corrections, status, finalize}) {
  return (
    <>
      <Button
        type={reviewMode ? 'primary' : 'default'}
        onClick={() => toggleReviewMode(!reviewMode)}
      >
        Toggle Review Mode
      </Button>
      <Button
        disabled={corrections < 1 && project.organization.id !== auth.sunkaizenUser.organization.id}
        onClick={() => finalize(corrections < 1, status)}
        type="primary"
        data-cy="returnToFuOrFinalizeBtn"
      >
        {corrections > 0 ? 'Return to Field User for Corrections' : 'Finalize'}
      </Button>
    </>
  )
}

const WrappedReviewOptions = connect(stop)(ReviewOptions)
export default WrappedReviewOptions
