import React from 'react'
import {auth} from '../../Auth'
import ButtonRenderer from './ButtonRenderer'
import NotScheduled from './NotScheduled'
import CancelledIntakes from './CancelledIntakes'
import InspectionDate from './InspectionDate'

export default function TopLevelState({project = {intake: {}, inspection: {}}}) {
  return (
    <div>
      {(!(project.intake || {}).noSchedule || project.status === 'Canceled' || project.status === 'Requested') && (
        <div className="flex-column align-start background-white padding-2 margin-bottom-2" style={{textAlign: 'left'}}>
          <div className="flex-row align-space-between full-width" style={{flex: 1}}>
            <h3 className="flex-row justify-start" style={{flex: 3}}>
              Intake Information
            </h3>
            {auth.sunkaizenUser &&
              ['admin', 'super-admin', 'client', 'field-user', 'scheduler'].includes(auth.sunkaizenUser.type) && (
                <ButtonRenderer project={project} />
              )}
          </div>
          {project.inspection && <InspectionDate project={project} />}
          {project.status !== 'Canceled' &&
            (!project.inspection || !project.inspection.date || project.status === 'To Be Scheduled') && ( // &&
              // ['field-user', 'client'].includes((project.inspection.inspector || {}).type)
              <NotScheduled
                id={project.id}
                organizationId={(project.organization || {}).id}
                inspector={project.inspection ? project.inspection.inspector : null}
                client={project.client}
              />
            )}
          <CancelledIntakes project={project} />
        </div>
      )}
    </div>
  )
}
