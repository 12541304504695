import React, { useState } from 'react';
import ScheduleInspections from '../ScheduleInspections/TopLevelState';
import { Modal, Button } from 'antd';
import CancelProjectModal from './CancelProjectModal';

export default function Scheduled({ project }) {
  const [showCancel, setShowCancel] = useState(false);
  const [schedule, setSchedule] = useState(false);

  return (
    <div>
      <div className="flex-row align-space-between full-width" style={{ flex: 1 }}>
        <Button
          className="margin-left--1"
          style={{ flex: 1 }}
          onClick={() => setSchedule(true)}
          icon="calendar"
        >
          Reschedule
        </Button>
        {project.status === 'Scheduled' && (
          <Button
            className="margin-left-1"
            style={{ flex: 1 }}
            onClick={() => setShowCancel(true)}
          >
            Cancel Inspection
          </Button>
        )}
      </div>
      <Modal
        width="95%"
        title="Schedule an Inspection"
        visible={schedule}
        onOk={() => setSchedule(false)}
        onCancel={() => setSchedule(false)}
        footer={null}
      >
        {/* Pass setVisible explicitly */}
        <ScheduleInspections setVisible={setSchedule} match={{ params: { id: project.id } }} location={{ pathname: '/schedule' }} />
      </Modal>
      <Modal
        width="95%"
        title="Cancel Intake"
        visible={showCancel}
        onOk={() => setShowCancel(false)}
        onCancel={() => setShowCancel(false)}
        footer={null}
      >
        <CancelProjectModal type="intake" id={project.id} />
      </Modal>
    </div>
  );
}
