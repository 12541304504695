import React from 'react'
import moment from 'moment'
import { Select } from 'antd'

const durationMap = {
  'last week': moment().subtract(7, 'days'),
  'last month': moment().subtract(1, 'months'),
  'last 3 months': moment().subtract(3, 'months'),
  'last 6 months': moment().subtract(6, 'months'),
  'last a year': moment().subtract(1, 'years'),
}

const Timespan = ({ value, setFilters }) => {
  const defaultKey = 'last week'
  // If a value exists, try to match it to one of our keys using isSame (by day)
  const selectedKey = value
    ? Object.keys(durationMap).find((key) => durationMap[key].isSame(value, 'day')) || defaultKey
    : defaultKey

  return (
    <Select
      size="large"
      placeholder="All Dates"
      onChange={(val) => setFilters('timespan', durationMap[val])}
      style={{ margin: '0 1rem 0 0', width: 150 }}
      value={selectedKey}
    >
      {Object.keys(durationMap).map((t) => (
        <Select.Option key={t} value={t}>
          {t}
        </Select.Option>
      ))}
    </Select>
  )
}

const Filters = ({ filters, setFilters }) => (
  <div
    className="padding-left-1 padding-right-1 flex-row align-center"
    style={{ justifyContent: 'flex-end' }}
  >
    <Timespan value={filters.timespan} setFilters={setFilters} />
  </div>
)

export default Filters
