import {emailRx} from '../../utilities'
import DB from '../../DAL/DB'
import {firebase} from '../../utilities'

const users = new DB.Users()

export const toggleEdit = (user, edit, logger, setEdit, initialUserType = null) => async () => {
  if (edit) {
    // Simple email validation.
    if (user.email && emailRx.test(user.email)) {
      if (['quality-manager', 'scheduler'].includes(initialUserType.current) && !['quality-manager', 'scheduler'].includes(user.type)) {
         if(user.clients)  user.clients = firebase.firestore.FieldValue.delete()
         if(user.clientsAll)  user.clientsAll = firebase.firestore.FieldValue.delete()
         if(user.qualifications) user.qualifications =  firebase.firestore.FieldValue.delete()
         if(user.qualificationsAll) user.qualificationsAll =  firebase.firestore.FieldValue.delete()
      }
       
      if (!['quality-manager', 'scheduler'].includes(initialUserType.current) && ['quality-manager', 'scheduler'].includes(user.type)) {
        console.log('can not afford to crash')
        if(!user.clients) user.clients = {}  
        if(!user.clientsAll) user.clientsAll = false
        if(!user.qualifications) user.qualifications = {}
        if(!user.qualificationsAll) user.qualificationsAll = false 
      }  

      //console.log('user',user)
      await users.update(user.id, null, user) 
      initialUserType.current = user.type
    } else {
      logger.error(`"${this.state.user.email}" is not a valid email address`)
      return
    }
  }

  setEdit(!edit)
}

export default toggleEdit
