import React, { useEffect, useState } from 'react';
import { subscribe } from '../../HOC/Firebase/SubscribeHOC';
import { StaticMap, Header, TypeRubric, MiscInfo, IntakeAndSnapshots, SaveReport, Tickets } from '.';
import {
  DownloadImages,
  CancelProjectButton,
  ApproveCancelButton,
  CustomPDFReport,
  InstallerViewable,
  AddTicket,
} from '.';
import '../DetailedProjectView.css';
import useSnapshots from './useSnapshots';
import DownloadStrategyReport from './StrategyReport';
import { correctedURL } from '../../utilities';
import { auth } from '../../Auth';
import { db } from '../../utilities';

function ProjectDetails({ history, project, makeSubscription, subscribeResources }) {
  const [reportURL, setReportURL] = useState(null);
  const [organization, setOrganization] = useState(null);

  const {
    created,
    updated,
    status,
    completed_on,
    id,
    rubric = { name: '' },
    client = { name: '', id: null },
    report = {},
    regenerate_report = {}
  } = project
  const {intake = {title: ''}, site = {geocode_results: {geometry: {location: {}}}}} = project

  // Retrieve snapshots using a custom hook.
  const snapshots = useSnapshots(id, makeSubscription, subscribeResources);

  // Correct the report URL if available.
  useEffect(() => {
    (async function () {
      if (!report.fullPath) return;
      const url = correctedURL(report.fullPath);
      setReportURL(url);
    })();
  }, [report.fullPath]);

  console.log("organization id :::: ", auth.sunkaizenUser.organization.id);

  // Fetch organization details from Firestore.
  useEffect(() => {
    async function fetchOrg() {
      try {
        const orgSnap = await db
          .collection('organizations')
          .doc(auth.sunkaizenUser.organization.id)
          .get();
        if (orgSnap.exists) {
          const orgData = orgSnap.data();
          console.log("Organization data:", orgData);
          setOrganization(orgData);
        } else {
          console.log("No organization record found");
        }
      } catch (error) {
        console.error("Error fetching organization:", error);
      }
    }
    fetchOrg();
  }, []);

  const thirdPartyInspector = organization?.third_party_inspector === true;

  return (
    <div className="flex-column background-white margin-left-2" data-cy="projectDetails">
      <Header {...{ status, completed_on }} />
      <StaticMap site={site} />
      <TypeRubric {...{id, intake, status, rubric}} />
      <MiscInfo {...{client, created, updated}} />
      <IntakeAndSnapshots {...{project, snapshots}} />
      <SaveReport {...{id, report, reportURL, site, intake, status, regenerate_report }} />
      <DownloadImages id={id} />
      {/* <DownloadStrategyReport /> */}
      <InstallerViewable {...{ project }} />
      <CustomPDFReport {...{ status, intake, site, id }} />
      {project?.tickets && <Tickets tickets={project.tickets} />}
      <AddTicket {...{ project, history, status, rubric }} />

      {!thirdPartyInspector && project.status !== 'Canceled' && project.status !== 'Cancel Requested' && (
        <CancelProjectButton id={id} />
      )}
      {!thirdPartyInspector && project.status === 'Cancel Requested' && (
        <ApproveCancelButton project={project} />
      )}
    </div>
  );
}

const WrappedProjectDetails = subscribe()(ProjectDetails);
export default WrappedProjectDetails;
