// reducer.js
import { createStore, combineReducers } from 'redux'

function rubrics(state = {}, action) {
  switch (action.type) {
    case 'rubrics':
      return action.value
    case 'rubric':
      return { ...state, [action.id]: action.value }
    case 'rubricField':
      return { ...state, [action.id]: { ...state[action.id], [action.field]: action.value } }
    case 'enableRubricActions':
      return { ...state, ...action.value }
    case 'enableAutoRubricValues':
      return action.value
    case 'rubricQuestion':
      // existing code...
      state[action.id].questions[action.question] = action.value
      return { ...state }
    case 'rubricQuestionActions':
      state[action.id].questions[action.question] = action.value
      return { ...state }
    default:
      return state
  }
}

function scoringSystems(state = {}, action) {
  switch (action.type) {
    case 'scoringSystems':
      return action.value
    case 'scoringSystem':
      return { ...state, [action.id]: action.value }
    case 'scoringSystemField':
      return { ...state, [action.id]: { ...state[action.id], [action.field]: action.value } }
    default:
      return state
  }
}

function forms(state = {}, action) {
  switch (action.type) {
    case 'forms':
      return action.value
    default:
      return state
  }
}

function sections(state = {}, action) {
  switch (action.type) {
    case 'sections':
      return action.value
    case 'section':
      return { ...state, [action.id]: { ...state[action.id], ...action.value } }
    case 'sectionField':
      return { ...state, [action.id]: { ...state[action.id], [action.field]: action.value } }
    case 'RESET_SECTIONS':
      return {}
    default:
      return state
  }
}

function form(state = {}, action) {
  switch (action.type) {
    case 'form':
      return action.value
    case 'formField':
      return { ...state, [action.id]: action.value }
    case 'enableAutoRubricOption':
      return { ...state, enableAutoRubric: action.value || false }
    default:
      return state
  }
}

function actionData(state = { actions: [] }, action) {
  switch (action.type) {
    case 'actionData':
      return { ...state, actions: action.value }
    default:
      return state
  }
}

// New reducer for the share form feature
function shareForm(state = false, action) {
  switch (action.type) {
    case 'SET_SHARE_FORM':
      return action.value
    default:
      return state
  }
}

const reducer = combineReducers({
  rubrics,
  scoringSystems,
  forms,
  sections,
  form,
  actionData,
  shareForm, // added new reducer
})

export const store = createStore(reducer)
