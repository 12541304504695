import BaseModel from './BaseModel'
import {db} from '../utilities'

export default class Organizations extends BaseModel {
  constructor() {
    super(db.collection('organizations'))
  }

  static async get(id: string) {
    try {
      const doc = await db.collection('organizations').doc(id).get();
      if (doc.exists) {
        return doc.data();
      } else {
        console.error("No such document!");
        return null ;
      }
    } catch (error) {
      console.error("Error getting document:", error);
      return null;
    }
  }
}
