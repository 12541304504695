import React, { useEffect, useReducer, useContext } from 'react';
import SystemLogger from '../SystemLogger';
import { auth } from '../Auth';

const initialState = { logs: {}, subscribers: {}, user: null };

function reducer(state, action) {
  switch (action.type) {
    case 'logs':
      return { ...state, logs: action.value };
    case 'log':
      return { ...state, logs: { ...state.logs, [action.value]: true } };
    case 'deleteLog': {
      const ll = { ...state.logs };
      delete ll[action.value];
      return { ...state, logs: ll };
    }
    case 'subscribers':
      return { ...state, subscribers: action.value };
    case 'subscriber':
      return { ...state, subscribers: { ...state.subscribers, [action.message]: action.timeout } };
    case 'user':
      return { ...state, user: action.value };
    default:
      return state;
  }
}

const ReducerContext = React.createContext();

export default function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ReducerContext.Provider value={{ dispatch, state }}>
      <SystemNotifier {...props} />
    </ReducerContext.Provider>
  );
}

export function SystemNotifier() {
  const { dispatch, state } = useContext(ReducerContext);

  useEffect(() => {
    return auth.sunkaizenUserSubscribe((user) => dispatch({ type: 'user', value: user }));
  }, [dispatch]);

  useEffect(
    function () {
      if (!state.user) return;

      // Subscribe to logs from SystemLogger
      return SystemLogger.subscribe(state.user.id)((message) => {
        dispatch({ type: 'log', value: message });
        const timeoutId = setTimeout(() => dispatch({ type: 'deleteLog', value: message }), 5000);
        dispatch({ type: 'subscriber', message, timeout: timeoutId });
      });
    },
    [state.user, dispatch]
  );

  function clear(message) {
    clearTimeout(state.subscribers[message]);
    dispatch({ type: 'deleteLog', value: message });
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: '2rem',
        right: '2rem',
        zIndex: 10000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '0.5rem',
      }}
    >
      {Object.keys(state.logs).map((log, index) => {
        // If the text includes "error" or "failed," color it red-ish
        const isError =
          log.toLowerCase().includes('error') ||
          log.toLowerCase().includes('failed') ||
          log.toLowerCase().includes('validation');

        return (
          <div
            key={`logs:${index}`}
            style={{
              position: 'relative',
              minWidth: '500px',
              maxWidth: '1000px',
              padding: '1rem 2.5rem 1rem 1rem',
              textAlign:'left',
              fontSize:'16px',
              color: isError ? '#721c24' : '#856404',
              backgroundColor: isError ? '#f8d7da' : '#fff3cd',
              border: isError ? '1px solid #f5c6cb' : '1px solid #ffeeba',
              borderRadius: '6px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              whiteSpace: 'pre-wrap', // preserve newlines
              cursor: 'pointer',
              transition: 'transform 0.2s',
            }}
            onClick={() => clear(log)}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.02)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            {/* Close "X" in top-right corner */}
            <span
              style={{
                position: 'absolute',
                top: '8px',
                right: '10px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.stopPropagation(); // prevent onClick from clearing
                clear(log);
              }}
            >
              ×
            </span>
            {log}
          </div>
        );
      })}
    </div>
  );
}
