import React from 'react'
import {Link} from 'react-router-dom'
import {Icon, message} from 'antd'
import {duplicate, del} from './utilities'
import DeleteButton from '../../../DeleteButton'

// const formatFirebaseTimestamp = (timestamp = null) => {
//     if (timestamp) return timestamp.toDate().toDateString()
// }

export default function columnsMap() {
  return [
    {
      title: `Form Name`,
      dataIndex: `title`,
      key: 'title',
      render: (text, record) => <Link to={`/forms/${record.id}`}>{text}</Link>,
      // sorter: true
    },
    {
      title: `Created`,
      dataIndex: `created`,
      key: `created`,
      sorter: true,
      render: (t) => <div>{t ? t.toDate().toDateString() : `N/A`}</div>,
    },
    {
      title: `Last Modified`,
      dataIndex: `updated`,
      key: 'updated',
      render: (t) => <div>{t ? t.toDate().toDateString() : `N/A`}</div>,
      sorter: true,
    },
    {
      title: `Created By`,
      dataIndex: `createdBy.name`,
      key: 'createdBy.name',
      render: (text, record) => (
        <div style={{fontStyle: `italic`}}>{record.createdBy ? record.createdBy.name : `Unidentified`}</div>
      ),
      // sorter: true
    },
    {
      title: `Published`,
      dataIndex: `active`,
      key: `active`,
      render: (text, record) => (record.active ? `Published` : `Unpublished`),
      filters: [
        {text: `Published`, value: true},
        {text: `Unpublished`, value: false},
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.active === value,
    },
    {
      title: `Rubric`,
      key: `rubrics`,
      dataIndex: `rubrics`,
      render: (text, record) => (
        <Link
          to={`/forms/${record.id}/rubrics`}
          onClick={(e) => e.stopPropagation()}
          style={{textDecoration: `underline`}}
        >
          {/* {console.log("record",record)} */}
          {`${record.rubrics && record.rubrics.length ? record.rubrics.length : 0} ${
            record.rubrics && record.rubrics.length === 1 ? 'Rubric' : 'Rubrics'
          }`}
        </Link>
      ),
    },
    {
      title: `Duplicate`,
      key: `duplicate`,
      render: (text, record) => (
        <Icon
          style={{padding: `0.2rem`, paddingLeft: `0.5rem`, fontSize: 24, cursor: `pointer`}}
          type="copy"
          onClickCapture={async (e) => {
            e.stopPropagation()
            try {
              message.info(`Duplicating form...`)
              const response = await duplicate(record.id)
              console.log('response',response)
              message.destroy()
              if (response?.status) {
                message.success(`Form duplicated`)
              }
              else {
                message.error(`Failed to duplicate form with error: ${response?.error}`)
              }
            } catch (error) {
              message.error(`Failed to duplicate form with error: ${error}`)
            }
          }}
        />
      ),
    },
    {
      title: `Delete`,
      render: (text, record) => (
        <div onClick={(e) => e.stopPropagation()}>
          <DeleteButton
            customRender
            onConfirm={() => del(record.id)}
            documentId={record.id}
            collection="forms"
            okText="Delete"
            cancelText="Cancel"
            confirmationDisplay="Really delete this form? This cannot be undone."
          >
            <Icon style={{padding: `0.2rem`, paddingLeft: `0.5rem`, fontSize: 24}} type="delete" />
          </DeleteButton>
        </div>
      ),
    },
  ]
}
