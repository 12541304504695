import { useState, useEffect, useContext } from 'react'
import { saveAs } from 'file-saver'
import moment from 'moment'
import { ReducerContext } from '../reducer'
import { getProjects, createCSV, fetchRubricsByFormIds } from '../utilities'

export default function useGetProjects(done, setDone) {
  const { state, dispatch } = useContext(ReducerContext)
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)

  window.stop_csv_report = !done

  useEffect(() => {
    (async function () {
      if (!done) {
        setLoading(false)
        return
      }
      setLoading(true)

      // 1. Fetch projects using the provided filters
      const projects = await getProjects({ ...state.filterTypes }, setPercent)

      const formIdsSet = new Set()
      projects.forEach((project) => {
        if (project.formId) {
          formIdsSet.add(project.formId)
        }
      })
      const formIds = Array.from(formIdsSet)

      const rubricsMap = await fetchRubricsByFormIds(formIds)

      projects.forEach((project) => {
        const rubricsForForm = rubricsMap[project.formId]
        project.rubricName = (rubricsForForm && rubricsForForm.length)
          ? rubricsForForm[0].name
          : ''
      })

      if (window.stop_csv_report) {
        setLoading(false)
        return
      }
      //console.log(JSON.stringify(projects[0], null, 2))
      const csvFile = createCSV(projects, state.includeIntakeComments)
      const blob = new Blob([csvFile], { type: 'text/csv' })

      const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss')
      const filename = `report_${timestamp}.csv`

      saveAs(blob, filename)
      setPercent(0)
      dispatch({ type: 'clearState' })
      setDone(false)
      setLoading(false)
    })()
  }, [done])

  return [loading, percent]
}
