import React, { useState, useEffect } from 'react';
import { auth } from '../Auth';
import { Button, Modal, Input, Checkbox, Popconfirm, Icon } from 'antd';
import DB from '../DAL/DB';
import { loggerEffect } from '../effects';
import { firebase } from '../utilities';
import moment from 'moment';

const projects = new DB.Projects();

export default function SiteNotes({ resource }) {
  const [logger, setLogger] = useState(null);
  const [notes, setNotes] = useState([]);

  // Modal state for adding a new note
  const [modalVisible, setModalVisible] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [newContactAttempted, setNewContactAttempted] = useState(false);

  // Function to sort notes descending by created.seconds
  const sortNotes = (notesArray) => {
    return notesArray.slice().sort((a, b) => {
      const aTime = a.created?.seconds || 0;
      const bTime = b.created?.seconds || 0;
      return bTime - aTime;
    });
  };

  useEffect(() => {
    if (resource.siteNotes) {
      setNotes(sortNotes(resource.siteNotes));
    }
  }, [resource.siteNotes]);

  useEffect(loggerEffect(setLogger), []);


  // Add new note via the modal
  async function handleAddNote() {
    const {
      currentUser: { displayName, uid: authorId },
      sunkaizenUser: { name },
    } = auth;
    const author = name || displayName;

    const newSiteNote = {
      created: firebase.firestore.Timestamp.now(),
      note: newNote,
      author,
      authorId,
      contactAttempted: newContactAttempted,
    };

    try {
      const updatedSiteNotes = [...(resource.siteNotes || []), newSiteNote];
      await projects.updatebatch(resource.id, null, {
        updatedBy: auth?.sunkaizenUser?.id,
        updatedByName: auth?.sunkaizenUser?.name,
        siteNotes: updatedSiteNotes,
      });
      const message = author ? `${author} added a site note` : `Site note added successfully`;
      logger.event(message);
      setNotes(sortNotes(updatedSiteNotes));
      setNewNote('');
      setNewContactAttempted(false);
      setModalVisible(false);
    } catch (e) {
      console.log('error adding a site note', e);
    }
  }

  // Delete a note (only allow deletion if current user is the author)
  function deleteSiteNote(i) {
    const updatedSiteNotes = [...(resource.siteNotes || [])];
    updatedSiteNotes.splice(i, 1);
    projects.updatebatch(resource.id, null, {
      updatedBy: auth?.sunkaizenUser?.id,
      updatedByName: auth?.sunkaizenUser?.name,
      siteNotes: updatedSiteNotes,
    });
    setNotes(sortNotes(updatedSiteNotes));
  }

  return (
    <div
      className="flex-column align-start background-white padding-2 margin-bottom-1"
      style={{ textAlign: 'left' }}
    >
      <h4>Notes</h4>
      <div
        className="full-width flex-column align-start padding-1"
        style={{ background: 'rgba(249, 250, 251, 0.7)' }}
      >
        <div className="full-width" style={{ padding: '0 0 0 0.5rem' }}>
          {notes.map((n, i) => (
            <div
              key={i}
              style={{
                borderBottom: '1px solid #ccc',
                marginBottom: '10px',
                paddingBottom: '10px',
              }}
            >
              <div className="flex-row justify-space-between align-center">
                <div className="bold padding-right-1">
                  {n.created?.toDate
                    ? n.created.toDate().toDateString()
                    : new Date(n.created._seconds * 1000).toDateString()}
                </div>
                {auth.currentUser.uid === n.authorId && (
                  <Popconfirm
                    onConfirm={() => deleteSiteNote(i)}
                    title="Are you sure you wish to delete this note?"
                  >
                    <Button type="link">
                    <Icon type="delete" style={{color:'red'}} />
                    </Button>
                  </Popconfirm>
                )}
              </div>
              <div className="flex-column" style={{ padding: '0 0 0 0.5rem' }}>
                <div className="padding-right-1 italic">
                  {n.author || 'unnamed'}:
                </div>
                <p>{n.note}</p>
                <div className="padding-top-1 flex-row">
                  <div className="padding-right-1">Attempted Contact:</div>
                  <Checkbox disabled checked={n.contactAttempted} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Single Add Note Button */}
        <div style={{ marginTop: '1rem' }}>
          <Button type="primary" onClick={() => setModalVisible(true)}>
            Add Note
          </Button>
        </div>
      </div>

      {/* Modal for adding a new note */}
      <Modal
        title="Add Note"
        visible={modalVisible}
        onOk={handleAddNote}
        onCancel={() => setModalVisible(false)}
      >
        <div style={{ marginBottom: '10px' }}>
          <Input.TextArea
            rows={3}
            placeholder="Enter notes here..."
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '10px' }}>Attempted Contact</span>
          <Checkbox
            checked={newContactAttempted}
            onChange={(e) => setNewContactAttempted(e.target.checked)}
          />
        </div>
      </Modal>
    </div>
  );
}
