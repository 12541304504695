import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'antd'
import Provider from './Provider'
import {blankFields} from '../action_creators'
import {store} from '../utilities'

export default function AddProjectButton({id, size, title, success = [], className, style, type, allowCreateProject= false}) {
  const [done, setDone] = useState(false)
  const [show, setShow] = useState(null)

  const [s, setS] = useState([])
console.log('allowCreateProject :::: ', allowCreateProject)
  useEffect(function () {
    setS([
      () => {
        setShow(false)
        console.log('closing modal')
      },
    ])
  }, [])

  return (
    <div>
      <Button {...{size, className, style, type}} onClick={() => setShow(true)}>
        {title ? title : 'Add Project'}
      </Button>
      <Modal
        width={'80%'}
        destroyOnClose={true}
        onCancel={() => {
          setDone('clear')
          store.dispatch(blankFields())
          setShow(false)
        }}
        onOk={() => {
          setDone('done')
        }}
        visible={show}
      >
        <Provider id={id} done={done} setDone={setDone} success={[...success, ...s]} allowCreateProject={allowCreateProject} />
      </Modal>
    </div>
  )
}
