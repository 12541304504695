import { store } from './store';
import { firebase } from '../../utilities';
import DB from '../../DAL/DB';
import { auth } from '../../Auth';

const projects = new DB.Projects();

/**
 * Silent save function that only updates the project data to Firestore.
 * This function is intended to run in the background without impacting UI or triggering re-renders.
 */
export async function silentSaveIntake() {
    const state = store.getState();
    const { project, sections, fields } = state;
  
    // Build project intake sections without triggering UI updates
    const intakeSections = { ...project.intake.sections };
    for (let i in sections) {
      intakeSections[i] = {
        ...sections[i],
        fields: (sections[i].order || []).map((fId) => fields[fId]).filter(Boolean),
      };
    }
  
    // Prepare project data for Firestore without changing Redux state
    const projectData = {
      ...project,
      intake: {
        ...project.intake,
        sections: intakeSections,
      },
      photos: fields[project.id]?.images || {},
      updated: firebase.firestore.Timestamp.now(),
      updatedBy: auth?.sunkaizenUser?.id,
      updatedByName: auth?.sunkaizenUser?.name,
    };

   
    if (projectData.status !== 'Completed') {
        projectData.completed_on = firebase.firestore.FieldValue.delete()
        projectData.completed_by = firebase.firestore.FieldValue.delete()
        console.log('del autosave completed_on')
    }
  
    if (projectData.status !== 'Completed') {
      projectData.completed_on = firebase.firestore.FieldValue.delete()
      projectData.completed_by = firebase.firestore.FieldValue.delete()
    }

    try {
      await projects.updatebatch(project.id, null, projectData);
      console.info('Silent save completed successfully.');
    } catch (e) {
      console.error('Error during silent save to Firestore:', e);
    }
  }
